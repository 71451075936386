import { Component, OnInit } from '@angular/core';
import {AccountsService} from '../../services/accounts/accounts.service';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';
import {WallitService} from '../../services/wallit/wallit.service';
import {ContentService} from '../../services/content/content-service.service';

@Component({
  selector: 'app-add-account-reminder',
  templateUrl: './add-account-reminder.component.html',
  styleUrls: ['./add-account-reminder.component.scss'],
})
export class AddAccountReminderComponent extends BaseComponent implements OnInit {

  protected noAccount = false;
  protected message = this.contentService.getMessage('addAccountReminder');

  constructor(
      private router: Router,
      private wallitService: WallitService,
      private usersService: UsersService,
      apiService: ApiService,
      private contentService: ContentService
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      this.noAccount = wallit.counterparties.length < 2;
    });
  }

  doItClicked() {
    this.router.navigate(this.isLoggedIn ? ['/Addbank', this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()] : ['/signup-username']);
  }

}
