import { Component, ViewChild, ElementRef, OnInit, SimpleChanges, OnChanges, forwardRef, Input, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import {errors} from './phone-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return '';
}

export function tryUSPhone(phoneNumber: string): boolean {
          try {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            const telNumber = phoneNumber;
            const pNumber = phoneUtil.parseAndKeepRawInput(telNumber, 'US');
            // const isValidNumber = phoneUtil.isValidNumber(pNumber);
              if (true /* TODO: figure out validation that works properly */ || phoneUtil.isValidNumberForRegion(pNumber, 'US')) {
                return true;
              } else {
                return false;
              }
          } catch (e) {
            return false;
          }
}

const PHONE_REGEXP_NOPREFIX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
const PHONE_REGEXP_PREFIX = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tel-field',
  templateUrl: './tel-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => TelFieldComponent),
     multi: true
   }
 ]
})

export class TelFieldComponent extends FormfieldbaseComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, Validator {

  @ViewChild('tl', { read: ElementRef }) em: ElementRef;
  @ViewChild('tl') telInput: { setFocus: () => void; } ;

  @Input() setFoc = false;
  @Input() getTel: string;
  @Input() formatWithPrefix = false;
  @Input() requiredPhone = false;
  // min and max number length for validators
  private minLenPhoneNumber: number;
  private maxLenPhoneNumber: number;
  private setFocusTl = false;

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('minlength');
            this.maxLength = controls[controlName].errors.hasOwnProperty('maxlength');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
       let message = 'Too few digits';
       if (this.required) { message = errors.required; }
       if (this.minLength) { message = errors.minLength; }
       if (this.maxLength) { message = errors.maxlength; }
       if (this.invalid) { message = errors.invalid; }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  constructor(private cd: ChangeDetectorRef) {
    super('phone');
    this.fieldForm = new FormGroup(
      {
        phone: new FormControl('')
      });
  }

  ngAfterViewInit(): void {
  }

  focusSearch() {
    this.telInput.setFocus();
  }

  ngOnInit(): void {
    if (this.formatWithPrefix) {
      this.minLenPhoneNumber = 15; // we use format +1 xxx-xxx-xxxx -> length = 15 chars
      this.maxLenPhoneNumber = 15; // we use format +1 xxx-xxx-xxxx -> length = 15 chars
    } else {
      this.minLenPhoneNumber = 12; // we use format xxx-xxx-xxxx -> length = 12 chars
      this.maxLenPhoneNumber = 12; // we use format xxx-xxx-xxxx -> length = 12 chars
    }
      if (this.requiredPhone) {
          this.fieldForm.get('phone').setValidators([
          Validators.required,
          Validators.minLength(this.minLenPhoneNumber),
          Validators.maxLength(this.maxLenPhoneNumber),
          Validators.pattern(PHONE_REGEXP_PREFIX)
        ]);
      } else {
        this.fieldForm.get('phone').setValidators([
          Validators.minLength(this.minLenPhoneNumber),
          Validators.maxLength(this.maxLenPhoneNumber),
          Validators.pattern(PHONE_REGEXP_PREFIX)
        ]);
      }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setFoc) {
      this.setFocusTl = true;
      setTimeout(() => {
        this.focusSearch();
      }, 300);
    }
    if (changes.getTel.currentValue && changes.getTel.currentValue !== '') {
      this.setValue(changes.getTel.currentValue);
    }
  }

  telBlurInput(event: any) {
    if (!this.requiredPhone && this.fieldForm.get('phone').value === '') {
      this.fieldForm.controls['phone'].clearValidators();
    }
    this.blurInput(event);
  }

}
