import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {AppSettings} from '../../app.settings';
import {RadiusService} from '../radius/radius.service';
import {UsersService} from '../users/users.service';
import {User} from 'firebase';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {BrandsService} from '../brands/brands.service';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {

  static Reward = {
    FIRST_GOAL: 'firstGoal',
    COMPLETE_FIRST_TASK: 'completeFirstTask',
    ASSIGN_TASK: 'assignTask',
    CONNECT_BANK_ACCOUNT: 'connectBankAccount',
    ADD_CHILD: 'addChild',
    HALFWAY_GOAL: 'halfwayGoal',
    COMPLETE_GOAL: 'completeGoal'
  };

  CACHING = false;

  private rewards;

  constructor(
      private restangular: Restangular,
      private radiusService: RadiusService,
      private brandsService: BrandsService
  ) { }

  private rewardParams = {
    brand: this.brandsService.getCampaignBrand()
  };

  getRewards(userId: string, readFromCache: boolean = false, dontFilter: boolean = false): Promise<any> {
    if (readFromCache && this.rewards) {
      return Promise.resolve(this.rewards);
    }
    return this.restangular.one('v3').all('rewards').customGETLIST('', this.rewardParams).toPromise().then(rewards => {
      if (!userId) {
        return rewards;
      }
      return dontFilter ? rewards : this.filterOutClaimedRewards(userId, rewards);
    });
  }

  getLocalRewards(userId: string, latitude: number, longitude: number): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).one('latitude', latitude).one('longitude', longitude).customGETLIST('', this.rewardParams).toPromise().then(rewards => {
      return rewards;
    });
  }

  getPublicRewards(): Promise<any> {
    return this.restangular.one('v3').one('rewards').all('public').customGETLIST('', this.rewardParams).toPromise().then(rewards => {
        return rewards;
    });
  }

  private filterOutClaimedRewards(userId, rewards): Promise<any> {
    // TODO: DO THIS ON THE SERVER SIDE
    return this.getEarnedRewards(userId).then(earnedRewards => {
      const result = rewards.filter(reward => (reward.schedule && +reward.schedule.count === 0) || !earnedRewards.find(earnedReward => earnedReward.rewardId === reward.id && earnedReward.status === 'claimed'));
      return result;
    });
  }

  getReward(userId: string, rewardId: string): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      return rewards.find(thisReward => rewardId === thisReward.id);
    });
  }

  findReward(userId: string, rewardName: string): Promise<any> {

    if (!this.brandsService.getUIConfig().snacks) {
      return Promise.resolve(undefined);
    }

    return this.getRewards(userId,  true).then(rewards => {
      return this.filterOutClaimedRewards(userId, rewards).then(filteredRewards => {
        return filteredRewards.find(thisReward => rewardName === thisReward.name.split(' ')[0]);
      });
    });
  }

  presentReward(userId: string, rewardId: string): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardId === thisReward.id);
      if (foundReward) {
        let amount;
        switch (foundReward.rewardDetails.amount.type) {
          case 'fixed':
            amount = foundReward.rewardDetails.amount.value;
            break;
          case 'randomLinear':
            amount = Math.random() * (foundReward.rewardDetails.amount.highValue -
                foundReward.rewardDetails.amount.lowValue) + foundReward.rewardDetails.amount.lowValue;
            if (amount.roundTo) {
              amount = Math.round(amount / foundReward.rewardDetails.amount.roundTo) * foundReward.rewardDetails.amount.roundTo;
            }
            break;
        }
        this.restangular.one('v3').one('rewards', userId).all('earned').customPOST({
          status: 'presented',
          parameters: { amount: amount },
          rewardId: foundReward.id
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  getUserRewardCount(userId: string, rewardName: string): Promise<number> {
    return this.getRewards(userId,  true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        return this.restangular.one('v3').one('user', userId).one('reward', foundReward.id).one('count').get().toPromise();
      }
      return Promise.resolve(-1);
    });
  }

  getGlobalRewardCount(userId: string, rewardName: string): Promise<number> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        return this.restangular.one('v3').one('reward', foundReward.id).one('count').get().toPromise();
      }
      return Promise.resolve(-1);
    });
  }

  addFormDataToReward(userId: string, rewardName: string, formData: any): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        this.restangular.one('v3').one('rewards', userId).all('earned').customPATCH({
          formData: formData,
          rewardId: foundReward.id
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  claimReward(familyId: string, userId: string, rewardId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {});
  }

  claimGoalReward(familyId: string, userId: string, rewardId: string, goalId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {goalId: goalId});
  }

  claimTaskReward(familyId: string, userId: string, rewardId: string, taskId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {taskId: taskId});
  }

  private claimRewardHelper(familyId: string, userId: string, rewardId: string, args: any): Promise<any> {
    return this.getRewards(userId,  false, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardId === thisReward.id);
      if (foundReward) {
        const patchPayload = Object.assign({}, {
          status: 'claimed',
          rewardId: foundReward.id
        }, args);
        // TODO: THIS MUST BE DONE ON THE SERVER
        return this.restangular.one('v3').one('rewards', userId).all('earned').customPOST(patchPayload).then(info => {
          return this.radiusService.reloadAccount(familyId, userId, info.amount, AppSettings.getRewardsInfo().wallitCounterParty);
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  getEarnedRewards(userId: string): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).all('earned').getList().toPromise().then(rewards => {
      return rewards;
    });
  }

  getRewardTransactions(familyId: string, userId: string): Promise<any> {
    // return this.restangular.one('families', familyId).one('users', userId).one('rewards').one('campaigns').all('transactions').getList().toPromise();
    return this.restangular.one('families', familyId).one('users', userId).one('rewards', 'x').one('available-campaigns').getList().toPromise();
  }

  claimRewardTransaction(familyId: string, userId: string, claimId: string): Promise<any> {
    const body = {
      claimed: true
    };
    return this.restangular.one('families', familyId).one('users', userId).one('rewards').one('campaigns').all('transactions', claimId).customPUT(body).toPromise();
  }

}
