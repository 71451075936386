import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {LedgerService} from '../../services/ledger/ledger.service';
import {AccountsService} from '../../services/accounts/accounts.service';
import {UtilsService} from '../../services/utils/utils.service';
import {ContentService} from '../../services/content/content-service.service';
import {WallitService} from '../../services/wallit/wallit.service';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.page.html',
  styleUrls: ['./transfer-history.page.scss'],
})
export class TransferHistoryComponent implements OnInit {

  @Input() enabled = true;

  familyId: string;
  userId: string;
  bankId: string;

  protected wallitBalance;
  protected dwollaBalance;
  protected familyBalance;
  protected pendingInboundTransferBalance;
  protected pendingOutboundTransferBalance;
  protected familyLedger: any;
  protected transactions = [];
  private plaidAccounts = [];
  private firstTime = true;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      protected usersService: UsersService,
      private utilsService: UtilsService,
      private ledgerService: LedgerService,
      private accountsService: AccountsService,
      private contentService: ContentService,
      private brandsService: BrandsService,
      private wallitService: WallitService,
  ) {
  }

  ngOnInit() {
    this.familyId = this.usersService.getCurrentFamilyId();
    this.userId = this.usersService.getCurrentUserId();
    if (this.firstTime && this.enabled) {
      this.firstTime = false;
      this.accountsService.getPlaidDwollaAccounts(this.familyId, this.userId).then(response => {
        const balanceAccount = response.find(account => account.fundingSourceType === 'balance');
        if (balanceAccount) {
          this.dwollaBalance = balanceAccount.fundingSourceBalance.value;
        }
        this.utilsService.addNoBalanceAccounts(this.familyId, this.userId, this.plaidAccounts).then(_ => {
          this.accountsService.getAccountBalances(this.familyId, this.userId).then(data => {
            this.plaidAccounts = this.plaidAccounts.concat(data);
            this.getTransfersForUserId(this.userId);
          }).catch(error => {
          });
        }).catch(error => {
        });
      });
    }
  }

  private findPlaidAccount(account) {
    const plaidAccount = this.plaidAccounts.find(plaid => plaid.dwollaFundingSource && plaid.dwollaFundingSource.id === account.id);
    return plaidAccount ? plaidAccount.plaidAccount[0] : undefined;
  }

  lookupName(item) {
    console.log('LOOKUPNAME', item);
    const account = item.fundingSource;
    let name = account.fundingSourceName || account.name;
    const plaidAccount = this.findPlaidAccount(account);
    if (plaidAccount) {
      name += ' - ' + plaidAccount.mask;
    }
    return name;
  }

  private getTransfersForUserId(userId: string) {
    this.accountsService.getPlaidDwollaTransactions(this.familyId, userId).then(data => {
      if (data && data.length > 0) {
        data = data.filter(transaction => transaction.status !== 'begin');
        data = data.reduce(function (accum, current) {
          const foundIndex = accum.findIndex(item => item.id === current.id);
          if (foundIndex < 0) {
            accum.push(current);
          } else {
            if (accum[foundIndex].date < current.date) {
              accum[foundIndex] = current;
            }
          }
          return accum;
        }, []);
        data.forEach(transaction => {
          if (transaction.transactionType === 'withdraw') {
            transaction.fundingSource.name = 'Wallit Balance';
            transaction.amount = -transaction.amount;
          }
          if (transaction.transactionType === 'wallitReward') {
            transaction.fundingSource.name = 'Cash Back';
          }
        });
        data.forEach(item => {
          item.userId = userId;
          item.by = this.utilsService.getUserName(this.usersService.lookupFamilyMember(userId));
        });
        this.transactions = this.transactions.concat(data);
        this.transactions.sort(function (a, b) {
          if (a.date < b.date) {
            return 1;
          }
          if (a.date > b.date) {
            return -1;
          }
          return 0;
        });
        data.forEach(transaction => {
          if (transaction.status === 'processed' && transaction.transactionType === 'reload') {
            this.pendingInboundTransferBalance += +transaction.amount;
          }
          if (transaction.status === 'processed' && transaction.transactionType === 'withdraw') {
            this.pendingOutboundTransferBalance += +transaction.amount;
          }
          if (transaction.status === 'processed' && transaction.transactionType === 'tangoCard') {
            if (transaction.amount > 0) {
              this.pendingOutboundTransferBalance += +transaction.amount;
            } else {
              this.pendingInboundTransferBalance += +transaction.amount;
            }
          }
          if (transaction.status === 'processed' && transaction.transactionType === 'withdraw') {
            if (transaction.amount > 0) {
              this.pendingOutboundTransferBalance += +transaction.amount;
            } else {
              this.pendingInboundTransferBalance += +transaction.amount;
            }
          }
          if (transaction.status === 'completed' || transaction.status === 'processed') {
            this.wallitBalance += +transaction.amount;
          }
        });
      }
    });
  }

  cashbackInfo(transaction) {
    return transaction.cashbackInfo;
  }

  getCardClass(transaction) {
    let extras = '';
    switch (transaction.status) {
      case 'cancelled':
        extras = 'cancelled';
        break;
      case 'failed':
        extras = 'failed';
        break;
      case 'processed':
        extras = 'pending';
    }
    return 'transfercard' + extras;
  }

  getMemberIcon(member) {
    return this.usersService.getAvatarOld(this.usersService.lookupFamilyMember(member.userId));
  }

  getMemberName(data): string {
    return data.name;
  }

  getMemberBalance(data): number {
    return data.balance.value;
  }

  transactionClicked(transaction) {
    this.router.navigate(['transaction-detail', this.familyId, transaction.userId, transaction.id]);
  }

}

@Pipe({
  name: 'getLine1',
  pure: true
})
export class GetTransferHistoryLine1Pipe implements PipeTransform {

  transform(item: any, thisArg: TransferHistoryComponent): string {
    if (thisArg.lookupName(item) === 'Wallit Balance') {
      return 'Transferred to your connected account';
    } else {
      return 'Transferred to your Wallit Balance';
    }
  }

}

@Pipe({
  name: 'getLine2',
  pure: true
})
export class GetTransferHistoryLine2Pipe implements PipeTransform {

  transform(item: any, thisArg: TransferHistoryComponent): string {
    return thisArg.cashbackInfo(item);
  }

}


