import { Injectable } from '@angular/core';
import { FirestoreBase} from './FirestoreBase';
import { AngularFirestore } from '@angular/fire/firestore';

export interface TrackedItem {
  id: string;
  forUserId: string;
  timestamp?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class FirestoreService {

  public goals = new FirestoreBase(this.db, 'goals');
  public tasks = new FirestoreBase(this.db, 'tasks');
  public members = new FirestoreBase(this.db, 'members');

  constructor(
      private db: AngularFirestore
  ) { }

}
