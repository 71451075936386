import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {GoalsService} from '../../services/goals/goals.service';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session/session.service';
import {IonItemSliding} from '@ionic/angular';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {Location} from '@angular/common';
import {UsersService} from '../../services/users/users.service';
import {listAnimator} from '../../services/animations/animations.service';
import {LedgerService} from '../../services/ledger/ledger.service';
import {ApiService} from '../../services/api/api.service';
import {ExperimentService} from '../../services/experiments/experiment.service';
import {ContentService} from '../../services/content/content-service.service';

@Component({
  selector: 'app-user-my-goals',
  templateUrl: './user-my-goals.component.html',
  styleUrls: ['./user-my-goals.component.scss'],
  // animations: listAnimator
})

export class OurlyUserMyGoalsComponent implements OnInit, OnChanges {

  @Input() userId = '';
  @Input() familyId = '';
  @Input() isChild = false;

  private spinnerLevel;
  protected noGoalsMessage = this.contentService.getMessage('noGoalsMessage');
  protected noGoalsButtonLabel = this.contentService.getMessage('noGoalsButtonLabel');

  protected goals;

  constructor(
      private goalsService: GoalsService,
      private router: Router,
      private sessionService: SessionService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private ledgerService: LedgerService,
      private apiService: ApiService,
      private usersService: UsersService,
      private contentService: ContentService
  ) { }

  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    if (!this.usersService.isOnboardingCompleted()) {
      return;
    }
    let promise;
    if (this.isChild) {
      promise = this.goalsService.memberGetAll(this.userId);
    } else {
      promise = this.goalsService.getAll(this.userId);
    }
    promise.then((response) => {
      this.goals = this.sorted(response);
      if (this.isChild) {
        this.goalsService.memberGoalsSubscribe(result => { if (result) { this.goals = this.sorted(result); }});
      } else {
        this.goalsService.goalsSubscribe(result => { if (result) { this.goals = this.sorted(result); }});
      }
    });
  }
  addYourFirstGoal(event) {
    event.stopPropagation();
    this.router.navigate([ '/AddAGoal', this.userId ]);
  }

  ngOnChanges(): void {

  }

  currentGoalValue(goal) {
    // return Math.min(goal.cost, this.userLedgerBalance);
    return goal.balance.value;
  }

  sorted(goals) {
    return goals.sort((a, b) => a.title < b.title);

  }

  goToGoal(goal) {
    this.router.navigate([ '/GoalDetail', this.familyId, this.userId, goal.goalId ]);
  }

  goToAllGoals() {
    this.router.navigate([ '/goals', this.familyId, this.userId ]);
  }

  removeGoal(sliding: IonItemSliding, goal) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this goal?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.goalsService.remove(this.userId, goal.goalId).then((response) => {
              this.goalsService.reload();
              this.toastComponent.presentToast('Goal removed');
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

}
