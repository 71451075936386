import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountsService} from '../../../../services/accounts/accounts.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../services/session/session.service';
import {IonItemSliding} from '@ionic/angular';
import {ToastComponent} from '../../../toast/toast.component';
import {UiAlertService} from '../../../../services/ui-alert/ui-alert.service';
import {listAnimator} from '../../../../services/animations/animations.service';
import {UsersService} from '../../../../services/users/users.service';
import {LedgerService} from '../../../../services/ledger/ledger.service';
import { filter, pairwise } from 'rxjs/operators';
import {ApiService} from '../../../../services/api/api.service';
import {ExperimentService} from '../../../../services/experiments/experiment.service';
import {ContentService} from '../../../../services/content/content-service.service';
import {WallitService} from '../../../../services/wallit/wallit.service';

@Component({
  selector: 'app-user-accounts-dwolla',
  templateUrl: './user-accounts-dwolla.component.html',
  styleUrls: ['./user-accounts-dwolla.component.scss'],
  // animations: listAnimator
})
export class UserAccountsDwollaComponent implements OnInit {

  protected accountsBalance;
  protected banks = [];
  private sharedAccountInfo;
  protected wallit;
//  private plaidAccounts = [];
  private useChildSources = false;
  protected reauthRequired;
  protected spinnerLevel;
  private showNoAccountsMessage = false;
  private pendingInboundAmount;
//  private updatingAccounts = false;
  private noAccountsTextMessage = this.contentService.getMessage('connectAnAccount');
  private showButtonLinkAnAccount = true;

  @Input() familyId: string;
  @Input() userId: string;
  @Input() balance: number;
  @Input() selectOnly = false;
  @Output() accountSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private accountService: AccountsService,
      private router: Router,
      private apiService: ApiService,
      private sessionService: SessionService,
      private toastComponent: ToastComponent,
      private uiAlertService: UiAlertService,
      private accountsService: AccountsService,
      private usersService: UsersService,
      private ledgerService: LedgerService,
      private contentService: ContentService,
      private wallitService: WallitService
  ) { }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      this.wallit = JSON.parse(JSON.stringify(wallit));
      this.banks = JSON.parse(JSON.stringify(this.wallit.counterparties));
      console.log('PLAIDACCOUNTS', this.wallit.plaidAccounts);
      console.log('BANKS', this.banks);
    });
    this.wallitService.monitorTransferOccurred(transfers => {
      this.pendingInboundAmount = transfers.pendingInboundAmount;
    });
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    // TODO: Brittle code checking for Dashboard in URL. Switch to using @Input parameter
    const currUrl = this.router.url;
    if (currUrl.includes('Dashboard')) {
      this.showNoAccountsMessage = true;
    }
    if (currUrl.includes('ChildDetail')) {
      this.noAccountsTextMessage = 'This child does not have any linked accounts';
      this.showButtonLinkAnAccount = false;
    }
    const member = this.usersService.lookupFamilyMember(this.userId) || this.usersService.me().user;
    if (member.permissionLevel === 1) {
      this.useChildSources = true;
    }
  }

  isNobalanceAccount(account): boolean {
    return account.fundingSourceType === 'nobalance';
  }

  isBalanceAccount(account): boolean {
    return account.fundingSourceType === 'balance';
  }

  needsVerify(account) {
    return account.fundingSourceType === 'needsverification';
  }

  pendingVerification(account): boolean {
    return account.fundingSourceType === 'pendingverification';
  }

  needsReauth(account) {
    return account.fundingSourceType === 'needsreauth';
  }

  lookupIcon(account) {
    return  this.isBalanceAccount(account) || !account.institution ? '/assets/logo/Wallit - Brandmark.svg' : account.institution.logo;
  }

  lookupName(account) {
    if (this.isBalanceAccount(account)) {
      return 'Wallit Balance';
    }
    if (this.needsVerify(account) || this.pendingVerification(account) || this.isNobalanceAccount(account)) {
      return account.name;
    }
    if (this.wallit.plaidAccounts.length === 0 && !this.useChildSources) {
      return '';
    }
    const plaidAccount = this.findPlaidAccount(account);
    const mask = this.useChildSources ? account.mask : (plaidAccount ? plaidAccount.mask : '');
    const name = account.fundingSourceName || account.name;
    return (mask && account.fundingSourceType !== 'creditcard') ? name + ' - ' + mask : name;
  }

  getPending(account) {
    return account.fundingSourceType === 'balance' ? this.pendingInboundAmount : 0;
  }

  lookupBalance(account) {
    if (this.useChildSources) {
      return account.balances.current;
    }
    if (this.isBalanceAccount(account)) {
      return account.balances ? account.balances.current : parseFloat(this.wallit.memberLedgerBalance);
    }
    const plaidAccount = this.findPlaidAccount(account);
    if (plaidAccount) {
      return (plaidAccount.balances.available && account.fundingSourceType !== 'creditcard') ? plaidAccount.balances.available : plaidAccount.balances.current;
    }
    return undefined;
  }

  gotBalance(account) {
    return this.isBalanceAccount(account) || (this.useChildSources ? true : this.findPlaidAccount(account));
  }

  private updateBankBalances() {
  }

  private findPlaidAccount(account) {
      const plaidAccount = this.wallit.plaidAccounts.find(plaid => (plaid.plaidAccount[0].type === 'credit' && plaid.plaidAccount[0].account_id === account.accountId) || (plaid.dwollaFundingSource &&
        plaid.dwollaFundingSource.id === account.fundingSourceId));
      return plaidAccount ? plaidAccount.plaidAccount[0] : undefined;
  }

  goToAccount(account) {
    if (this.useChildSources) {
      if (!this.isBalanceAccount(account)) {
        const fundingSourceId = this.sharedAccountInfo.sharedFundingSources.
         find(sharedAccount => sharedAccount.plaidAccountId === account.account_id).dwollaFundingSourceId;
        this.router.navigate([ '/BankDetail', this.familyId,
          this.usersService.me().user.id, account.account_id, fundingSourceId ]);
      } else {
        this.router.navigate([ '/child-transfer' ] );
      }
    } else {
      if (this.needsVerify(account) || this.pendingVerification(account)) {
        return;
      }
      if (this.isNobalanceAccount(account)) {
          this.router.navigate(['/BankDetail', this.familyId, this.userId, account.account_id]);
        return;
      }
      const plaidAccount = this.findPlaidAccount(account);
      if (plaidAccount) {
        this.router.navigate(['/BankDetail', this.familyId, this.userId, plaidAccount.account_id, account.fundingSourceId ? account.fundingSourceId : 'credit']);
      } else {
        this.router.navigate(['/tabs/tab-activity']);
      }
    }

  }

  verifyClicked(event, account) {
    event.stopPropagation();
    this.accountsService.getPublicToken(account.accessToken).then(data => {
      this.router.navigate(['/Addbank', this.familyId, this.userId], { queryParams: {publicToken: data.publicToken, microDeposits: true}});
    });
  }

  reauthClicked() {
    this.router.navigate(['/Addbank', this.familyId, this.userId], { queryParams: {public_token: this.reauthRequired}});
  }

  linkAnAccountClicked() {
    this.router.navigate(['/Addbank', this.familyId, this.userId]);
  }

  accountsClicked() {
    this.router.navigate([ '/Payments', this.familyId, this.userId ]);
  }

  removeAccount(sliding: IonItemSliding, account) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this account?').then(confirm => {
      sliding.close();
      if (confirm) {
        const promise = !account.fundingSourceId ? this.accountsService.removePlaidAccount(this.familyId, this.userId, account.accessToken) :
         this.accountsService.removeAccount(this.familyId, this.userId, account.fundingSourceId);
        promise.then((response) => {
              this.toastComponent.presentToast('Account removed');
              this.accountsService.notifyAccountChanged('');
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }
}
