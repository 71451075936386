import {Component, Input, OnInit} from '@angular/core';
import {AccountsService} from '../../services/accounts/accounts.service';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-smart-balance-card',
  templateUrl: './smart-balance-card.component.html',
  styleUrls: ['./smart-balance-card.component.scss'],
})
export class SmartBalanceCardComponent implements OnInit {

  smartBalance = 100.00;

  private currentAccountIndex = 0;

  @Input() info;

  constructor(
      private accountsService: AccountsService,
      private usersService: UsersService,
      private router: Router
  ) { }

  ngOnInit() {
  }

  getSmartBalance() {
    return this.info.smartBalance;
  }

  getDate() {
    return this.info.date;
  }

  gearClicked() {
    this.router.navigate(['smart-balance-settings',  this.info.bankId, this.info.plaidAccountId ]);
  }

}
