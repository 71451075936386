import {Component, Input, OnInit} from '@angular/core';
import {BrandsService} from '../../services/brands/brands.service';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../base/base.component';
import {UsersService} from '../../services/users/users.service';
import {AppSettings} from '../../app.settings';
import {WallitService} from '../../services/wallit/wallit.service';

@Component({
  selector: 'app-header-team',
  templateUrl: './header-team.component.html',
  styleUrls: ['./header-team.component.scss'],
})
export class HeaderTeamComponent extends BaseComponent implements OnInit {

  protected releaseVersion;

  @Input() myBalance: number;

  constructor(
      protected brandsService: BrandsService,
      private router: Router,
      apiService: ApiService,
      private usersService: UsersService,
      private wallitService: WallitService
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.releaseVersion = AppSettings.getReleaseVersion();
    this.usersService.meSubscribe((me) => {
      if (me) {
        this.wallitService.monitorTransferOccurred(balances => {
          this.myBalance = balances.myBalance;
        });
      } else {
        this.myBalance = undefined;
      }
    });
  }

  loginClicked() {
    this.router.navigate([ '/SignIn' ]);
  }

  joinClicked() {
    this.router.navigate([ '/signup-username' ]);
  }

  menuClicked() {
    this.router.navigate([ '/WallitActions' ]);
  }

  balanceClicked() {
    this.router.navigate(['tabs/tab-accounts'], { replaceUrl: true });
  }

}
