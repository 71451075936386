import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

declare var google;

@Injectable({
    providedIn: 'root'
})

export class LocationService {

    private locationObservable: BehaviorSubject<any>;

    defaultLocation = { latitude: 43.6591, longitude: -70.2568 };   // Our home in Portland, ME

    public locationEnabled = false;

    constructor(
    ) {
        this.locationObservable = new BehaviorSubject(null);
        Geolocation.getCurrentPosition({ timeout: 10000 }).then((resp) => {
            this.locationObservable.next(resp);
            this.locationEnabled = true;
            Geolocation.watchPosition({}, (data) => {
                this.locationObservable.next(data);
            });
        }).catch(error => {
            // console.log(error);
        });
    }

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::                                                                         :::
// :::  This routine calculates the distance between two points (given the     :::
// :::  latitude/longitude of those points). It is being used to calculate     :::
// :::  the distance between two locations using GeoDataSource (TM) prodducts  :::
// :::                                                                         :::
// :::  Definitions:                                                           :::
// :::    South latitudes are negative, east longitudes are positive           :::
// :::                                                                         :::
// :::  Passed to function:                                                    :::
// :::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
// :::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
// :::    unit = the unit you desire for results                               :::
// :::           where: 'M' is statute miles (default)                         :::
// :::                  'K' is kilometers                                      :::
// :::                  'N' is nautical miles                                  :::
// :::                                                                         :::
// :::  Worldwide cities and other features databases with latitude longitude  :::
// :::  are available at https://www.geodatasource.com                         :::
// :::                                                                         :::
// :::  For enquiries, please contact sales@geodatasource.com                  :::
// :::                                                                         :::
// :::  Official Web site: https://www.geodatasource.com                       :::
// :::                                                                         :::
// :::               GeoDataSource.com (C) All Rights Reserved 2018            :::
// :::                                                                         :::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    distance(lat1, lon1, lat2, lon2, unit = 'M') {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        } else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') {
                dist = dist * 1.609344;
            }
            if (unit === 'M') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    }

    here(): Promise<any> {
        if (this.locationObservable && this.locationObservable.getValue()) {
            return Promise.resolve(this.locationObservable.getValue().coords);
        }
        return Promise.resolve(this.defaultLocation);
        /*
        return Geolocation.getCurrentPosition( {timeout: 10000 }).then( () => {
            return resp => resp.coords;
        }).catch(error => {
            this.locationEnabled = false;
            return Promise.resolve(this.defaultLocation);
        });
        */
    }

    hereSubscribe(func) {
        return this.locationObservable.subscribe(func);
    }

    distanceFromHere(lat, long): Promise<any> {
        return this.here().then(result => {
            return this.distance(lat, long, result.latitude, result.longitude, 'M');
        });
    }

    distanceSubscribe(lat, long, observer) {
        const _this = this;
        function calculateDistance(info) {
            if (info && info.coords) {
                observer(_this.distance(lat, long, info.coords.latitude, info.coords.longitude, 'M'));
            }
        }
        this.locationObservable.subscribe(calculateDistance);
    }

    /*
    BEWARE: Costs $0.005/inquiry
    reverseGeocode(lat, long, func) {
        console.log('reverseGeocode', typeof lat, long)
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({'location': {lat: lat, lng: long}}, function(results, status) {
            if (status === 'OK') {
                if (results.length) {
                    func(results[results.length - 1].formatted_address);
                } else {
                    func('');
                }
            } else {
                func('');
            }
        });
    }
     */
}
