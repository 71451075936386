import {Component, Input, OnInit} from '@angular/core';
import {RebatesService} from '../../services/rebates/rebates.service';
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../base/base.component';
import {LocationService} from '../../services/location/location.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rebates-list',
  templateUrl: './rebates-list.component.html',
  styleUrls: ['./rebates-list.component.scss'],
})
export class RebatesListComponent extends BaseComponent implements OnInit {

  @Input() title;
  @Input() latitude;
  @Input() longitude;
  @Input() radius = 10;
  @Input() categorySlug;

  protected rebateItems;
  private here;

  constructor(
      private rebatesService: RebatesService,
      private locationService: LocationService,
      private router: Router,
      apiService: ApiService,
  ) {
    super(apiService);
  }

  ngOnInit() {
    if (this.latitude && this.longitude) {
      this.loadTheList(this.latitude, this.longitude);
    } else {
      this.locationService.here().then(here => {
        this.here = here;
        this.loadTheList(here.latitude, here.longitude);
      });
    }
  }

  loadTheList(latitude, longitude) {
    this.rebatesService.getRebatesByCategoryAndLocation(this.categorySlug, latitude, longitude, this.radius).then(rebates => {
      this.rebateItems = rebates;
      this.rebateItems.forEach(rebate => {
        this.locationService.distanceFromHere(rebate.merchant.latitude, rebate.merchant.longitude).then(result => {
          rebate.distance = result;
          this.sortByDistance();
        });
        this.locationService.distanceSubscribe(rebate.merchant.latitude, rebate.merchant.longitude, function(distance)  {
          rebate.distance = distance;
          this.sortByDistance();
        }.bind(this));
      });
      this.sortByDistance();
    });
  }

  sortByDistance() {
    this.rebateItems = this.rebateItems.sort((a, b) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    });
  }

  getDistance(rebate) {
    return rebate.distance ? rebate.distance.toFixed(1) : '';
  }

  rebateClicked(rebateItem) {
    if (this.isLoggedIn) {
      this.router.navigate(['rebatedetail', rebateItem.type, rebateItem.id]);
    }
  }

  showAllClicked() {
    this.router.navigate(['rebates', this.here.latitude, this.here.longitude, 10]);
  }

  getDiscount(rebate) {
    const percentage = rebate.discount_percentage * 100;
    return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}%`;
  }

  getCashback(rebate) {
    if (rebate.price) {
      const cashback = rebate.cashback_percentage * rebate.price;
      return `$${(cashback).toFixed(cashback < 10 ? 2 : 0)}`;
    } else {
      const percentage = rebate.cashback_percentage * 100;
      return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}%`;
    }
  }

  getAndWord(rebate) {
    return rebate.discount_percentage && rebate.cashback_percentage ? 'and ' : '';
  }

  getDiscountIcon() {
    return 'assets/icon/discount.png';
  }

  getCashbackIcon() {
    return 'assets/icon/cashback.png';
  }

}
