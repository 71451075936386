import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class OurlyTransactionComponent implements OnInit {

  @Input() transaction: any;
  @Input() disabled: false;
  @Input() authorizeMode = false;
  @Output() reimburseEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  recurrenceTypeLabel(transaction) {
    switch (transaction.recurrenceType) {
      case 'daily':
        return 'Daily';
      case 'weekly':
        return 'Weekly';
      case 'every-other-week':
        return 'Every Other Week';
      case 'every-two-weeks':
        return 'Every Two Weeks';
      case'monthly':
        return 'Monthly';
      case'quarterly':
        return 'Quarterly';
      case'annually':
        return 'Annually';
    }
  }

  reimburseClicked(event) {
    this.reimburseEvent.emit(event.detail.value);
  }

}

