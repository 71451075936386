import {Component, NgZone} from '@angular/core';
import { BrowserDetectService } from './services/browser-detect/browser-detect.service';
import {Platform, AlertController} from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
const { App } = Plugins;
import {BrandsService} from './services/brands/brands.service';
import {NavigationEnd, Router} from '@angular/router';
import {ToastComponent} from './components/toast/toast.component';
import {UsersService} from './services/users/users.service';
import {ExperimentService} from './services/experiments/experiment.service';
import { HttpClient } from '@angular/common/http';
// import {GeofenceService} from './services/geofence/geofence.service';
import {PushnotificationsService} from './services/pushnotifications/pushnotifications.service';
import {ApiService} from './services/api/api.service';
import {StripeService} from './services/stripe/stripe.service';

declare var ga: Function;
declare var gtag: Function;
// declare var fbq: Function;
declare var ire: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  private publicIP = '';
  private ipLocData = [];

  constructor(
    private router: Router,
    private platform: Platform,
//    private statusBar: StatusBar,
    private brandsService: BrandsService,
    private browserDetectService: BrowserDetectService,
    public alertCtrl: AlertController,
    private usersService: UsersService,
    private toastComponent: ToastComponent,
    private experimentService: ExperimentService,
    private apiService: ApiService,
    private http: HttpClient,
    private stripeService: StripeService,
    private zone: NgZone,
//    private geofenceService: GeofenceService,
    private pushNotificationsService: PushnotificationsService
  ) {
    this.initializeApp();
  }

  /*
  isUpdateAvailable = new Promise(function(resolve, reject) {
    // lazy way of disabling service workers while developing
//    if ('serviceWorker' in navigator && ['localhost', '127'].indexOf(location.hostname) === -1) {
      // register service worker file
      navigator.serviceWorker.register('./version.js')
          .then(reg => {
            reg.onupdatefound = () => {
              const installingWorker = reg.installing;
              installingWorker.onstatechange = () => {
                switch (installingWorker.state) {
                  case 'installed':
                    if (navigator.serviceWorker.controller) {
                      // new update available
                      resolve(true);
                    } else {
                      // no update available
                      resolve(false);
                    }
                    break;
                }
              };
            };
          })
          .catch(err => console.error('[Service worker error]', err));
//    }
  });
  */
  ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return true;
    }
    return false;
  }

  getIpifyData(): Promise<any[]> {
    return this.http.get<any[]>('https://api.ipify.org?format=json').toPromise();
  }

  getIpLocData(ipaddress): Promise<any[]> {
    return this.http.get<any[]>('https://tools.keycdn.com/geo.json?host=' + ipaddress).toPromise();
  }

  initializeApp() {
    this.experimentService.getVariant();
    if (this.platform.is('capacitor')) {
      App.addListener('appUrlOpen', data => {
        this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = data.url.split('.app').pop();
          if (slug) {
            console.log('+++++++++++++++++++++++ URL', slug);
            this.router.navigate([slug]);
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    }
//    this.geofenceService.init();
    SplashScreen.hide().catch(_ => {});
      this.platform.ready().then(() => {
        this.pushNotificationsService.setBrand(this.brandsService.getCampaignBrand(), (title, msg) => {
          this.toastComponent.presentToast(title + ' - ' + msg);
        });
        const dfb = this.browserDetectService.detectBrowser(true); // show false browser message
        if (!navigator.cookieEnabled) {
          // The browser does not support or is blocking cookies from being set.
          this.showAlert(); // cookie message
        }
        // get public ip
        /*
        this.getIpifyData().then( ipaddress => {

              if (ipaddress && ipaddress.hasOwnProperty('ip') && this.ValidateIPaddress(ipaddress['ip'])) {
                this.publicIP = ipaddress['ip'];
                // NOTICE: BLOCKED FROM LOCALHOST  - CORS
                this.getIpLocData(this.publicIP).then(iploc => {

                  this.ipLocData = iploc;
                  if (this.ipLocData.length > 0 && ( iploc['data'].geo.country_code !== 'US'
                  && iploc['data'].geo.country_code !== 'CA'
                  && iploc['data'].geo.country_code !== 'HR'  )) {
                    this.showAlertIpLoc();
                  }
                }).catch( error => {
                  console.log('Error Getting IpLoc: ', error);
                  // this.showAlertIpLoc();
                });
              }
        }).catch( error => {
          console.log('Error Getting IpAddr: ', error);
        });
        */
        /*
        this.isUpdateAvailable.then(isAvailable => {
          if (isAvailable) {
            this.toastComponent.presentToast('New version of the Wallit app is available. Please reload.');
          }
        });
        */
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const userId = this.usersService.getCurrentUserId();
            ga('set', 'userId', userId);
            ga('set', 'brand', this.brandsService.getName());
            ga('set', 'page', event.urlAfterRedirects, event);
            ga('send', 'pageview');
            gtag({'event': 'optimize.activate'});

            // google ad tracking per MM-1095
            gtag('js', new Date());
            gtag('config', 'AW-736338767');

            // facebook tracking
            // fbq('track', 'PageView', event.urlAfterRedirects);
            this.experimentService.getVariant();
            // impact tracking
            // ire('identify', {customerId: userId ? userId : '', customerEmail: ''});
            // if (event.urlAfterRedirects === '/Dashboard') {
            //  ire('trackConversion', 17534, {orderId: userId});
            // }
            if (event.urlAfterRedirects === '/WallitActions' || event.urlAfterRedirects === '/intro-signup' || event.urlAfterRedirects === '/SignIn') {
              window['Intercom']('update', {hide_default_launcher: false});
            } else {
              window['Intercom']('update', {hide_default_launcher: true});
            }
          }
        });
      });
  }

  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Warning!',
      subHeader: 'Sorry, you need to enable cookies in your browser to use this app.',
      buttons: ['OK']
    });
    return await alert.present();
  }

  async showAlertIpLoc() {
    const alert = await this.alertCtrl.create({
      header: 'Warning!',
      subHeader: 'Sorry, Wallit is not available in your country yet!',
      buttons: ['OK']
    });
    return await alert.present();
  }

}
