import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Last4Pipe} from './last4/last4.pipe';
import {CapitalizePipe} from './capitalize/capitalize.pipe';
import {
    GetTransferHistoryLine1Pipe,
    GetTransferHistoryLine2Pipe
} from '../components/transfer-history/transfer-history.page';

@NgModule({
    declarations: [Last4Pipe, CapitalizePipe, GetTransferHistoryLine1Pipe, GetTransferHistoryLine2Pipe],
    imports: [
        CommonModule
    ],
    exports: [Last4Pipe, CapitalizePipe, GetTransferHistoryLine1Pipe, GetTransferHistoryLine2Pipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PipesModule { }
