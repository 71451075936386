import {Component, Input, OnInit, AfterViewChecked, OnChanges, SimpleChanges, AfterContentInit} from '@angular/core';
import {AccountsService} from '../../services/accounts/accounts.service';
import {Router} from '@angular/router';
import {SmartbalanceService} from '../../services/smartbalance/smartbalance.service';
import {UsersService} from '../../services/users/users.service';
import {WallitService} from '../../services/wallit/wallit.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class OurlyBankComponent implements OnInit, OnChanges {

  protected institutionImage;

  @Input() bank;
  @Input() nosmart = false;

  constructor(
      private accountsService: AccountsService,
      private router: Router,
      private smartBalanceService: SmartbalanceService,
      private usersService: UsersService,
      private wallitService: WallitService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.bank) {
      if (!this.wallitService.isRadius()) {
        this.bank.verified = true;
      }
      if (!this.nosmart) {
        this.smartBalanceService.getSmartBalances(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.bank.integrationId).then(result => {
          this.bank.smartBalance = result.find(balance => balance.plaidAccountId === this.bank.account_id).smartBalance;
        });
      }
      if (this.bank.institution) {
        this.accountsService.getInstitutionInfo(this.bank.institution.name).then(info => {
          this.institutionImage = info.logo ? 'data:image/jpeg;base64,' + info.logo : 'assets/icon/icons8-mastercard-credit-card-90.png';
        });
      }
    }
  }

  smartBalanceSettings() {
    this.router.navigate(['smart-balance-settings', this.bank.bankId, this.bank.account_id ]);
  }

}
