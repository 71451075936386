import {Component, Input, OnInit} from '@angular/core';
import {RadiusService} from '../../services/radius/radius.service';
import {UsersService} from '../../services/users/users.service';
import {Platform} from '@ionic/angular';
import {AccountsService} from '../../services/accounts/accounts.service';

@Component({
  selector: 'app-accountpicker',
  templateUrl: './accountpicker.component.html',
  styleUrls: ['./accountpicker.component.scss'],
})
export class AccountpickerComponent implements OnInit {

  @Input() label;
  @Input() placeholder;
  @Input() parent;

  private sources;
  protected selectStyle = this.getSelectStyle();

  constructor(
      private radiusService: RadiusService,
      private usersService: UsersService,
      private platform: Platform,
      private accountsService: AccountsService
  ) { }

  ngOnInit() {
    this.accountsService.getPlaidTokens(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(info => {
      return this.radiusService.getFundingSource(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(accounts => {
        this.sources = accounts;
        this.sources.forEach(source => {
          source.title = source.counterparty.plaid.name;
          source.sourceType = 'Account';
          info.find(plaidInfo => {
            if (plaidInfo.balanceCache) {
              const bank = plaidInfo.balanceCache.accounts.find(account => account.account_id === source.counterparty.plaid.account_id);
              if (bank) {
                source.balance = bank.balances.current;
                source.sourceId = source.id;
                return true;
              }
              return false;
            }
          });
        });
        this.radiusService.getSummaryBalances(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then( sb => {
          this.sources.unshift({title: 'My Wallit', sourceType: 'Wallit', sourceId: 'none', balance: sb.availableWallitBalance ? sb.availableWallitBalance : 0});
        });

      });
    });
  }

  getSelectStyle() {
    if (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone')) {
      return { interface: 'action-sheet', mode: 'ios' };
    } else {
      return { interface: 'alert', mode: 'md' };
    }
  }

  bankChanged(event) {
    console.log('bankChanged', event);
  }

}
