import {Subscription} from 'rxjs';
import {TrackedItem} from './firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';

export class FirestoreBase {

    private collectionName: string;

    constructor(
        private db: AngularFirestore,
        collectionName: string
    ) {
        this.collectionName = collectionName;
    }

    private get(item: TrackedItem) {
        return this.db.collection<TrackedItem>(this.collectionName,
            ref => ref.where('forUserId', '==' , item.forUserId).where('id', '==', item.id).limit(1)).snapshotChanges();
    }

    update(item: TrackedItem) {
        item.timestamp = new Date();
        this.get(item).subscribe( val => {
            if (val.length > 0) {
                this.db.doc(this.collectionName + '/' + val[0].payload.doc.id).update(item);
            } else {
                this.add(item);
            }
        });
    }

    add(item: TrackedItem) {
        item.timestamp = new Date();
        return this.db.collection<TrackedItem>(this.collectionName).add(item);
    }

    delete(item: TrackedItem) {
        this.get(item).subscribe( val => {
            if (val.length > 0) {
                this.db.doc(this.collectionName + '/' + val[0].payload.doc.id).delete();
            }
        });
    }

    monitor(userId: string, func): Subscription {
        return this.db.collection<TrackedItem>(this.collectionName,
            ref => ref.where('forUserId', '==', userId)).valueChanges().subscribe(data => {
            func(data);
        });
    }

}
