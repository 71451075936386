import { Restangular } from 'ngx-restangular';
import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, ConnectableObservable, timer} from 'rxjs';
import {debounce, debounceTime} from 'rxjs/operators';
import {LocalStorageServiceProvider} from '../local-storage-service/local-storage-service';
import {LoadingController} from '@ionic/angular';
import {PushnotificationsService} from '../pushnotifications/pushnotifications.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

    static spinnerObservable = new BehaviorSubject(0);
    static spinnerLevel = 0;
    static loading;
    static staticLoadingController;

    private loginChangedObservable = new BehaviorSubject(false);

    private headers = {
        'content-type': 'application/json; charset=utf-8',
    };

    static spinnerIncrement() {
        this.spinnerObservable.next(++this.spinnerLevel);
    }

    static spinnerDecrement() {
        if (this.spinnerLevel > 0) {
            this.spinnerObservable.next(--this.spinnerLevel);
        }
    }

    static async showWaiting(msg) {
        const loading = await ApiService.staticLoadingController.create({
            message: msg
        });
        ApiService.loading = loading;
        return await loading.present();
    }

    static hideWaiting() {
        if (ApiService.loading) {
            ApiService.loading.dismiss();
            ApiService.loading = undefined;
        }
    }

    constructor(
    private restangular: Restangular,
    private localStorageService: LocalStorageServiceProvider,
    private loadingController: LoadingController,
    private pushnotificationsService: PushnotificationsService
    ) {
        ApiService.staticLoadingController = loadingController;
  }

 public setToken(token) {
    this.unsetToken();
    this.addHeader('Authorization', 'Bearer ' + token);
    this.pushnotificationsService.init(jwt_decode(token).userId);
    this.loginChangedObservable.next(true);
  }

  public unsetToken() {
    this.removeHeader('Authorization');
    this.loginChangedObservable.next(false);
  }

  private addHeader(key, value) {
    this.headers[key] = value;
    this.restangular.provider.setDefaultHeaders(this.headers);
  }

  private removeHeader(key) {
    delete this.headers[key];
    this.restangular.provider.setDefaultHeaders(this.headers);
  }

  spinnerSubscribe(func) {
      function syncFunc(val) { setTimeout(() => {
          func(val);
        });
      }
      ApiService.spinnerObservable.pipe(debounceTime(300)).subscribe(syncFunc);
  }

  isAuthenticated(): boolean {
    if (!this.headers['Authorization']) {
        const token = this.localStorageService.getStorage('JWTToken');
        if (token) {
            this.setToken(token);
        }
    }
    return this.headers['Authorization'];
  }

  monitorLoginState(func) {
    this.loginChangedObservable.subscribe(value => func(value));
    func(this.isAuthenticated());
  }

}
