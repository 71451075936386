import {EventEmitter, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

    private transfersChangedEvent: EventEmitter<string> = new EventEmitter<string>();

    constructor(
      private restangular: Restangular
  ) { }

    notifyTransfersChanged(id: string) {
      this.transfersChangedEvent.emit(id);
    }

    monitorTransfersChanged(func) {
        this.transfersChangedEvent.subscribe(value => func(value));
    }

    private p2pTransferHelper(familyId: string, userId: string, body: any): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').one('wallit').one('transactions').customPOST(body).toPromise().then(data => {
        this.notifyTransfersChanged(data);
        return data;
    });
  }

  makeP2PAccountTransfer(familyId: string, userId: string, targetAccountId: string, amount: number, comment: string): Promise<any> {
    return this.p2pTransferHelper(familyId, userId, {amount: amount, comment: comment, destinationAccountId: targetAccountId});
  }

  makeP2PWallitTransfer(familyId: string, userId: string, targetUserId: string, amount: number, comment: string, requestId: string): Promise<any> {
    return this.p2pTransferHelper(familyId, userId, {amount: amount, comment: comment, destinationUserId: targetUserId, requestTransactionId: requestId});
  }

  makeP2PExternalTransfer(userId: string, useAccount: boolean, fundingSourceId: string, amount: number, comment: string, inviteType: string, inviteId: string): Promise<any> {
      const data = {
          amount: amount,
          comment: comment,
          inviteType: inviteType, // 'email' or 'phoneNumber'
          inviteId: inviteId,
          fundingSourceType: useAccount ? 'account' : 'wallitBalance',
          fundingSourceId: fundingSourceId,
          userType: 'parent',
          firstName: '',
          lastName: '',
          baseDomain: window.location.hostname // temporary required to avoid issues on different brands
      }
      return this.restangular.one('v4').one('users', userId).one('invites').one('external').customPOST(data).toPromise().then(data => {
          this.notifyTransfersChanged(data);
          return data;
      });
  }

  makeP2PExternalRequest(userId: string, amount: number, comment: string, inviteType: string, inviteId: string): Promise<any> {
    const data = {
        amount: amount,
        comment: comment,
        inviteType: inviteType, // 'email' or 'phoneNumber'
        inviteId: inviteId,
        userType: 'parent',
        firstName: '',
        lastName: '',
        baseDomain: window.location.hostname
    }
    return this.restangular.one('v4').one('users', userId).one('invites').one('externalrequest').customPOST(data).toPromise().then(data => {
        this.notifyTransfersChanged(data);
        return data;
    });
  }

  getMyP2PTransactions(familyId: string, userId: string, filter: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2ptransactions?filter=' + filter).getList().toPromise();
  }

  getP2PTransactionsByUserId(familyId: string, userId: string, destinationUserId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2ptransactions', destinationUserId).getList().toPromise();
  }

  requestP2PFunds(familyId: string, userId: string, targetUserId: string, amount: number, comment: string, email: boolean, sms: boolean): Promise<any> {
      const data = {
          targetUserId: targetUserId,
          amount: amount,
          comment: comment,
          sendSmsNotification: sms,
          sendEmailNotification: email,
          baseDomain: window.location.hostname
      };
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pRequests').customPOST(data).toPromise().then(data => {
          this.notifyTransfersChanged(data);
          return data;
      });
  }

  getP2PRequestsSent(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pRequests').one('sent').getList().toPromise();
  }

  getP2PRequestsReceived(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pRequests').one('received').getList().toPromise();
  }

  cancelP2Prequest(familyId: string, userId: string, requestId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pRequests', requestId).one('status', 'cancel').put().toPromise().then(data => {
          this.notifyTransfersChanged(data);
          return(data);
      });
  }

  getUserP2PHistory(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pTransactions').one('histories').getList().toPromise();
  }

  addUserToP2PHistory(userId: string, addedUserId: string): Promise<any> {
      return this.restangular.one('v4').one('users', userId).one('users').one('favorite-users-transactions', addedUserId).customPOST().toPromise();
  }

  getUserP2PDetails(familyId: string, userId: string, destinationUserId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('p2pTransactions', destinationUserId).get().toPromise();
  }

}
