import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-team-info-card',
  templateUrl: './team-info-card.component.html',
  styleUrls: ['./team-info-card.component.scss'],
})
export class TeamInfoCardComponent implements OnInit {

  @Input() teamMember;

  constructor() { }

  ngOnInit() {}

}
