import {AfterViewInit, Component, Input, OnInit, ViewChildren} from '@angular/core';
import {RebatesService} from '../../services/rebates/rebates.service';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {Router} from '@angular/router';
import * as ellipsis from 'ftellipsis';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';

@Component({
  selector: 'app-rebates-carousel',
  templateUrl: './rebates-carousel.component.html',
  styleUrls: ['./rebates-carousel.component.scss'],
})
export class RebatesCarouselComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() categories;
  @Input() title;
  @ViewChildren('description') descriptions;

  protected rebateItems;
  protected slideOpts = {
    spaceBetween: -40,
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination'
    }
  };
  constructor(
    private rebatesService: RebatesService,
    private router: Router,
    apiService: ApiService,
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.rebatesService.getRebatesByCategory(this.categories).then(rebates => {
      this.rebateItems = rebates;
    });
  }

  ngAfterViewInit() {
    this.descriptions.changes.subscribe(changes => changes.forEach(description => {
      const descriptionEllipsis = new ellipsis(description.nativeElement);
      descriptionEllipsis.calc();
      descriptionEllipsis.set();
    }));
  }

  rebateClicked(rebateItem) {
    if (this.isLoggedIn) {
      this.router.navigate(['rebatedetail', 'groupon', rebateItem.id]);
    }
  }

  showAllClicked() {
    this.router.navigate(['/tabs/tab-offers/rebatelist/' + this.categories]);
  }

  getDiscountType(rebate) {
    return 'Off';
  }

  getDiscount(rebate) {
    return `${(rebate.discount_percentage * 100).toFixed(0)}%`;
  }

}
