import {Platform} from '@ionic/angular';
import {APP_TARGET} from './version';

export class AppSettings {

  private static PRODUCTION_ENDPOINT = 'https://production.api.wallit.app'; // 'https://app.ourly.com/api';
  private static STAGING_ENDPOINT = 'https://production.api.wallit.app';
  private static QA_ENDPOINT = 'https://qa.api.wallit.app'; 'https://qa.api.internal.wallit.app/api'; // 'https://us-central1-wallit-qa-api.cloudfunctions.net/api'; // 'https://qa.api.internal.wallit.app/api';
  private static DEMO_ENDPOINT = 'https://production.api.wallit.app';
  private static LOCAL_ENDPOINT = 'http://localhost:8080';

  public static HELP_ENDPOINT = 'https://www.wallit.app/';

  private static isDemo: boolean = window.location.host.indexOf('demo') >= 0;
  private static isQa: boolean = window.location.host.indexOf('qa') >= 0 || APP_TARGET === 'qa';
  public static isStaging: boolean = window.location.host.indexOf('staging') !== -1 || APP_TARGET === 'staging';
  public static isProd: boolean = window.location.host.indexOf('my') !== -1 || APP_TARGET === 'prod';

  private static  demoFirebaseConfig = {
    apiKey: 'AIzaSyBZ4qoEt676wxjpHzT8BuVMfRUZik10j2Y',
    authDomain: 'wallit-v3-development.firebaseapp.com',
    databaseURL: 'https://wallit-v3-development.firebaseio.com',
    projectId: 'wallit-v3-development',
    storageBucket: 'wallit-v3-development.appspot.com',
    messagingSenderId: '115048290364'
  };

  private static qaFirebaseConfig = {
    apiKey: 'AIzaSyBM4vdg0N_YpysT-ipiUO3mHGYEw22LkCM',
    authDomain: 'wallit-v3-qa.firebaseapp.com',
    databaseURL: 'https://wallit-v3-qa.firebaseio.com',
    projectId: 'wallit-v3-qa',
    storageBucket: 'wallit-v3-qa.appspot.com',
    messagingSenderId: '974119717465'
  };

  private static stagingFirebaseConfig = {
    apiKey: 'AIzaSyAzUfeuxjqEuoAkLidb3ciYDckvawJrYBg',
    authDomain: 'ourly-v3-staging.firebaseapp.com',
    databaseURL: 'https://ourly-v3-staging.firebaseio.com',
    projectId: 'ourly-v3-staging',
    storageBucket: 'ourly-v3-staging.appspot.com',
    messagingSenderId: '1037278910892'
  };

  private static productionFirebaseConfig = {
    apiKey: 'AIzaSyDiine2mI2TjqI09GmvNjflNi81rldbWCU',
    authDomain: 'wallit-v3-production.firebaseapp.com',
    databaseURL: 'https://wallit-v3-production.firebaseio.com',
    projectId: 'wallit-v3-production',
    storageBucket: 'wallit-v3-production.appspot.com',
    messagingSenderId: '846931494557'
  };

  private static STRIPE_SANDBOX = {
    key: 'pk_test_51IG3Y1JQ7tkZop4Eyg8j9kMh65HLTFwXeMzJ9KEeI7HqLlvHuLCzFWrgwEFvBKrb2CLrKmETmlLpSs4b1yZ1QhAA00zSmmle7e',
  }

  private static STRIPE_PROD = {
    key: 'pk_live_51IG3Y1JQ7tkZop4Edab3EB5mTzj4hwshJinyAmgrbn3J0kJ7hbQlBRzuU5q6AZnTpsd9w96KEhMeVyJZZ5tbBDLT00OxYVZsEf',
  }

  private static DWOLLA_SANDBOX = 'sandbox';
  private static DWOLLA_PROD = 'prod';

  private static PLAID_SANDBOX = {
    env: 'sandbox',
    key: '2abf2e2c85785d0f31bb220328d26b',
  };

  private static PLAID_PROD = {
    env: 'production',
    key: '2abf2e2c85785d0f31bb220328d26b'
  };

  private static PLAID_DEVELOPMENT = {
    env: 'development',
    key: '2abf2e2c85785d0f31bb220328d26b'
  };

  private static LOGGLY_SANDBOX = {
    logglyKey: 'ac1f4368-fe7f-40c2-8446-424702d9b1c2',
    sendConsoleErrors: true,
    tag: 'loggly-logger'
  };

  private static LOGGLY_PROD = {
    logglyKey: 'ac1f4368-fe7f-40c2-8446-424702d9b1c2',
    sendConsoleErrors: false,
    tag: 'loggly-logger'
  };

  private static LOCAL_UI = {
    spendingChart: true,
    lockedGoals: true
  };

  private static DEMO_UI = {
      spendingChart: true,
      goalDestinations: false,
      goalAutoSave: true,
      snacks: true,
      lockedGoals: false,
      p2p: false,
      giftcards: false,
      bank: 'dwolla',
      family: true,
      tasks: false,
      wellness: true,
      plans: false,
      oldUi: true
  };

  private static QA_UI = {
    spendingChart: true,
    goalDestinations: false,
    goalAutoSave: false,
    snacks: true,
    lockedGoals: false,
    p2p: false,
    giftcards: false,
    bank: 'dwolla',
    family: false,
    transferIn: false,
    tasks: false,
    wellness: true,
    plans: true,
    oldUi: false
  };

  private static STAGING_UI = {
    spendingChart: true,
    goalDestinations: false,
    goalAutoSave: false,
    snacks: true,
    lockedGoals: false,
    p2p: false,
    giftcards: false,
    bank: 'dwolla',
    family: false,
    tasks: false,
    transferIn: false,
    wellness: true,
    plans: true,
    oldUi: true
  };

  private static PRODUCTION_UI = {
    spendingChart: true,
    goalDestinations: false,
    goalAutoSave: false,
    snacks: true,
    lockedGoals: false,
    p2p: false,
    giftcards: false,
    bank: 'dwolla',
    family: false,
    tasks: false,
    transferIn: false,
    wellness: true,
    plans: true,
    oldUi: true
  };

  private static QA_REWARDS = {
    wallitCounterParty: 'cp_11espnf3rt4kg'
  }

  private static PRODUCTION_REWARDS = {
    wallitCounterParty: 'cp_11espn36g686q'
  }

  public static API_ENDPOINT: string = AppSettings.getEndpoint();
  public static LOGGLY_CONFIG = AppSettings.getLogglyConfig();
  public static PLAID_ENVIRONMENT = AppSettings.getPlaidEnvironment();

  constructor(private platform: Platform) {
  }

  public static getStripeInfo() {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
        return this.STRIPE_SANDBOX;
      case 'demo':
        return this.STRIPE_SANDBOX;
      case'qa':
        return this.STRIPE_SANDBOX;
      case 'staging':
        return this.STRIPE_PROD;
      case 'prod':
      case 'localprod':
        return this.STRIPE_PROD;
      default:
        if (this.isDemo) {
          return this.STRIPE_PROD;
        } else if (this.isQa) {
          return this.STRIPE_SANDBOX;
        } else if (this.isStaging) {
          return this.STRIPE_PROD;
        } else if (this.isProd) {
          return this.STRIPE_PROD;
        } else {
          return  this.STRIPE_PROD;
        }
    }
  }

  public static getRewardsInfo(): any {
    return (this.isDemo || this.isQa) ? AppSettings.QA_REWARDS : AppSettings.PRODUCTION_REWARDS;
  }

  public static getReleaseVersion(): string {
    if (this.isDemo) {
      return 'Demo';
    } else if (this.isQa) {
      return 'QA';
    } else if (this.isStaging) {
      return 'Staging';
    } else if (this.isProd) {
      return '';
    } else {
      return  '';
    }
  }

  public static getFirebaseConfig() {
    if (this.isDemo) {
      return this.demoFirebaseConfig;
    } else if (this.isQa) {
      return this.qaFirebaseConfig;
    } else if (this.isStaging) {
      return this.stagingFirebaseConfig;
    } else if (this.isProd) {
      return this.productionFirebaseConfig;
    } else {
      return  this.productionFirebaseConfig;
    }
  }

  public static getUIConfig(): any {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
        return this.LOCAL_UI;
      case 'demo':
        return this.DEMO_UI;
      case'qa':
        return this.QA_UI;
      case 'staging':
        return this.STAGING_UI;
      case 'prod':
      case 'localprod':
        return this.PRODUCTION_UI;
      default:
        if (this.isDemo) {
          return this.DEMO_UI;
        } else if (this.isQa) {
          return this.QA_UI;
        } else if (this.isStaging) {
          return this.STAGING_UI;
        } else if (this.isProd) {
          return this.PRODUCTION_UI;
        } else {
          return  this.PRODUCTION_ENDPOINT;
        }
    }
  }

  public static getBrandOverride(): string {
    return new URLSearchParams(document.location.search).get('brand');
  }

  public static getEndpoint() {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
      case 'localprod':
        return this.LOCAL_ENDPOINT;
      case 'demo':
        return this.DEMO_ENDPOINT;
      case'qa':
        return this.QA_ENDPOINT;
      case 'staging':
        return this.STAGING_ENDPOINT;
      case 'prod':
        return this.PRODUCTION_ENDPOINT;
      default:
        if (this.isDemo) {
          return this.DEMO_ENDPOINT;
        } else if (this.isQa) {
          return this.QA_ENDPOINT;
        } else if (this.isStaging) {
          return this.STAGING_ENDPOINT;
        } else if (this.isProd) {
          return this.PRODUCTION_ENDPOINT;
        } else {
          return  this.PRODUCTION_ENDPOINT;
        }
    }
  }

  public static getPlaidEnvironment() {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
        return this.PLAID_SANDBOX;
      case 'demo':
        return this.PLAID_SANDBOX;
      case'qa':
        return this.PLAID_SANDBOX;
      case 'staging':
        return this.PLAID_PROD;
      case 'prod':
      case 'localprod':
        return this.PLAID_PROD;
      default:
        if (this.isQa) {
          return this.PLAID_SANDBOX;
        } else {
          return this.PLAID_PROD;
        }
    }
  }

  public static getLogglyConfig() {
    if (this.isDemo || this.isQa) {
      return this.LOGGLY_SANDBOX;
    } else {
      return this.LOGGLY_PROD;
    }
  }

}

const LOG = (!AppSettings.isProd && !AppSettings.isStaging) ? console.log.bind(console) : function () {};
