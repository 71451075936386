import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {ToastComponent} from '../toast/toast.component';
import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';
import {UtilsService} from '../../services/utils/utils.service';
import {TransferService} from '../../services/transfer/transfer.service';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import 'wallit-contacts';
import {Plugins} from '@capacitor/core';

const { WallitContactsPlugin } = Plugins;

@Component({
  selector: 'app-queryusers',
  templateUrl: './queryusers.component.html',
  styleUrls: ['./queryusers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueryusersComponent implements OnInit, OnDestroy {

  @Output() userSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  private qrCodeAvailable;
  protected showScanner = false;
  protected users = [];
  private topFriendsList = [];
  private topPeopleList = [];
  protected filteredTopFriendsList = [];
  protected filteredTopPeopleList = [];
  private scannerOk;
  private scanSubscription;
  protected qrUser = {
    isQr: true,
    firstName: 'Scan',
    lastName: 'Code',
    email: this.scannerOk ? 'Quick pay or request' : 'Scan requires Safari browser',
    avatar: '/assets/icon/icons8-qr-code-64.png'
  };

  @ViewChild('searchbar') searchbar;

  constructor(
      private usersService: UsersService,
      private router: Router,
      private platform: Platform,
      private toastComponent: ToastComponent,
      private contacts: Contacts,
      private utilsService: UtilsService,
      private transferService: TransferService,
      private qrScanner: QRScanner
  ) { }

  ngOnInit() {
    this.qrCodeAvailable = this.platform.is('cordova');
    const isMobileSafari = !this.platform.is('capacitor') && this.platform.is('ios') && navigator.userAgent.indexOf('CriOS');
    this.scannerOk = this.qrCodeAvailable || isMobileSafari;
    console.log(this.qrCodeAvailable, this.scannerOk, navigator.userAgent);
    this.transferService.getUserP2PHistory(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(users => {
      this.topFriendsList = users;
    });
    WallitContactsPlugin.fetchContactsInBackground().then(contacts => {
      this.users = contacts.map(contact => ({
        firstName: contact.givenName,
        lastName: contact.familyName,
        email: contact.emailAddresses.length > 0 ? contact.emailAddresses[0].value : ''
      }));
      const friendList = [];
      contacts.forEach(contact => {
        (contact.phoneNumbers || []).forEach(phoneNumber => friendList.push({ phone: this.utilsService.fixPhoneNumber(phoneNumber.value)}));
        (contact.emails || []).forEach(email => friendList.push({ email: email.value}));
      });
      this.usersService.findFriends(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), friendList).then(users => {
        this.topFriendsList = users.filter(user => user.firstName !== '-');
        this.filteredTopFriendsList = this.topFriendsList;
      });
    });
    this.transferService.getUserP2PHistory(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(users => {
      this.topPeopleList = users;
      this.filteredTopPeopleList = this.topPeopleList;
    });
    this.filterItems();
  }

  displayContents(err, text) {
    if (err) {
      // an error occurred, or the scan was canceled (error code `6`)
    } else {
      // The scan completed, display the contents of the QR code:
      alert(text);
    }
  }

  ngOnDestroy() {
    if (this.scanSubscription) {
      this.scanSubscription.unsubscribe();
    }
    this.scanSubscription = null;
    (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
    this.qrScanner.hide();
    this.qrScanner.destroy();
  }

  scan() {

    this.showScanner = true;  // TEMP

    this.qrScanner.prepare()
        .then((status: QRScannerStatus) => {
          (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
          if (status.authorized) {
            this.showScanner = true;
            this.qrScanner.show();
            this.scanSubscription = this.qrScanner.scan().subscribe((text: string) => {
              this.showScanner = false;
              try {
                const barcodeJSON = JSON.parse(text);
                if (barcodeJSON.appId && barcodeJSON.appId === 'wallit.app') {
                  this.usersService.queryUserById(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), barcodeJSON.userId).then(user => {
                    this.userSelectedEvent.emit(user);
                  });
                } else {
                  this.toastComponent.presentToast('Not a Wallit QR code');
                  this.scan();
                }
              } catch (e) {
                this.toastComponent.presentToast('Not a Wallit QR code');
                this.scan();
              }
            });
          } else {
            this.toastComponent.presentToast('Please enable camera access in Settings');
          }
        })
        .catch((e: any) => {
          console.error('Error', e);
        });
  }

  userClicked(member) {
    if (member.isQr) {
      if (this.scannerOk) {
        this.scan();
      }
    } else {
      this.userSelectedEvent.emit(member);
    }
  }

  filterItems() {
    const isMatch = person => {
      const testField = name => {
        const fieldValue = person[name];
        if (!fieldValue) {
          return false;
        }
        return fieldValue.indexOf(this.searchbar.value) >= 0;
      }
      return testField('firstName') || testField('lastName') || testField('email') || testField('loginName');
    }
    this.filteredTopPeopleList = this.topPeopleList.filter(person => isMatch(person));
    this.filteredTopFriendsList = this.topFriendsList.filter(person => isMatch(person));
    this.usersService.queryUsers(this.searchbar.value || '').then(users => {
      this.users = users;
    });
  }

  inviteUserClicked() {
    if (this.utilsService.isPhoneNumber(this.searchbar.value)) {
      this.userSelectedEvent.emit({ phone: this.utilsService.fixPhoneNumber(this.searchbar.value) } );
    } else {
      this.userSelectedEvent.emit({ newEmail: this.utilsService.fixPhoneNumber(this.searchbar.value) } );
    }
  }

}
