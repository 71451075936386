import { Injectable } from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ToastComponent} from '../../components/toast/toast.component';
import {SessionService} from '../session/session.service';
import {FamilyService} from '../family/family.service';
import {UsersService} from '../users/users.service';
import {UiAlertService} from '../ui-alert/ui-alert.service';
import {PhoneValidator} from '../../validators/phone-number.validator';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {AccountsService} from '../accounts/accounts.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  activeValidator = false;
  public timeAgo;

  constructor(
      private toastComponent: ToastComponent,
      private sessionService: SessionService,
      private familyService: FamilyService,
      private usersService: UsersService,
      private uiAlertService: UiAlertService,
      private accountsService: AccountsService
  ) {
      TimeAgo.addLocale(en);
      this.timeAgo = new TimeAgo('en-US');
  }

  public sendPhoneChangeSMS(phone: string, oldPhone: string, donefunc) {
      this.usersService.setPhoneNumber(phone).then(result => {
          if (oldPhone) {
              this.sessionService.deletePhoneNumber(oldPhone).then(response => {
              }).catch((error) => {
                  this.toastComponent.presentError(error);
              });
          }
          this.uiAlertService.presentAlertEnterPIN(phone).then(pin => {
              this.sessionService.loginValidateCode(phone, pin).then(_ => {
                  donefunc();
              }).catch((error) => {
                  this.usersService.removePhoneNumber(phone);
                  this.toastComponent.presentToast('Invalid SMS code');
              });
          }).catch(error => {
              this.usersService.removePhoneNumber(phone);
          });
      }).catch((error) => {
          this.toastComponent.presentError(error);
      });
  }

  public getWeekNumber() {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    return Math.ceil( (((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7 );
  }

  public getDateFromWeek(week: number) {
      const d = (1 + (week - 1) * 7); // 1st of January + 7 days for each week
      return new Date(new Date().getFullYear(), 0, d);
  }

  getDayGreeting(): string {
      const today = new Date();
      const curHr = today.getHours();
      if (curHr < 12) {
          return 'Good morning, ';
      } else if (curHr < 18) {
          return 'Good afternoon, ';
      } else {
          return 'Good evening ';
  }

}

  public fixSubcategory(subcategory: string) {
    if (subcategory === 'generic') {
      return 'custom';
    }
    return subcategory;
  }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

  public getAge(dateString): number {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  public getUserName(user): string {
    return user.firstName || user.nickname || user.familyAlias;
  }

  public isPhoneNumber(phone: string): boolean {
      const regex = /^1?\s?^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return regex.test(phone);
  }

  public fixPhoneNumber(phone: string): string {
      if (!phone) {
          return '';
      }
    let phoneNumber = phone.replace(/\D+/g, '');
    phoneNumber = phoneNumber.replace('-', '');
    if (phone && phoneNumber[0] !== '1') {
      phoneNumber = '1' + phoneNumber;
    }
    return phoneNumber;
  }

  public comparePhoneNumbers(phone1: string, phone2: string): boolean {
    return this.fixPhoneNumber(phone1) === this.fixPhoneNumber(phone2);
  }

  public getPhoneNumber(response: any): string {
    let number = response.phoneNumber || (response.phoneNumbers && response.phoneNumbers.length > 0 ?
     response.phoneNumbers[0].phoneNumber : '');
    if (number && number[0] === '1') {
      number = number.substr(1);
    }
    return number;
  }

  public getPhoneNumberWithDashes(response: any) {
      let phone = this.getPhoneNumber(response);
      if (phone) {
          phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6, 15);
      }
      return phone;
  }

    eitherOrPhoneEmailUnsubscribe(info) {
      if (info) {
          info[0].unsubscribe();
          info[1].unsubscribe();
      }
    }

  magicLinkValidator(form: FormGroup) {
    if (form.get('magiclink')) {
        const phoneValidator = [];
        const emailValidator = [];
        const emailControl = form.get('email');
        const telControl = form.get('tel');
        telControl.setValidators(phoneValidator);
        emailControl.setValidators(emailValidator);
        telControl.updateValueAndValidity();
        emailControl.updateValueAndValidity();
          return [emailControl.valueChanges.subscribe(
            (value: string) => {
              if (this.activeValidator === false) {
                this.activeValidator = true;
                if (!value) {
                  telControl.setValidators(phoneValidator);
                  emailControl.clearValidators();
                } else {
                  telControl.clearValidators();
                  emailControl.setValidators(emailValidator);
                }
                telControl.updateValueAndValidity();
                emailControl.updateValueAndValidity();
                this.activeValidator = false;
              }
            }),
        telControl.valueChanges.subscribe(
            (value: string) => {
              if (this.activeValidator === false) {
                this.activeValidator = true;
                if (!value ) {
                  emailControl.setValidators(emailValidator);
                  telControl.clearValidators();
                } else {
                  emailControl.clearValidators();
                  telControl.setValidators(phoneValidator);
                }
                telControl.updateValueAndValidity();
                emailControl.updateValueAndValidity();
                this.activeValidator = false;
              }
            })
        ];
    } else {
      this.eitherOrPhoneEmailValidator(form);
    }

  }

  eitherOrPhoneEmailValidator(form: FormGroup) {
    const phoneValidator = [ Validators.required, PhoneValidator.validCountryPhone ];
    const emailValidator = [ Validators.required, Validators.email ];
    const emailControl = form.get('email');
    const telControl = form.get('tel');
    telControl.setValidators(phoneValidator);
    emailControl.setValidators(emailValidator);
    telControl.updateValueAndValidity();
    emailControl.updateValueAndValidity();
      return [emailControl.valueChanges.subscribe(
        (value: string) => {
          if (this.activeValidator === false) {
            this.activeValidator = true;
            if (!value) {
              telControl.setValidators(phoneValidator);
              emailControl.clearValidators();
            } else {
              telControl.clearValidators();
              emailControl.setValidators(emailValidator);
            }
            telControl.updateValueAndValidity();
            emailControl.updateValueAndValidity();
            this.activeValidator = false;
          }
        }),
    telControl.valueChanges.subscribe(
        (value: string) => {
          if (this.activeValidator === false) {
            this.activeValidator = true;
            if (!value ) {
              emailControl.setValidators(emailValidator);
              telControl.clearValidators();
            } else {
              emailControl.clearValidators();
              telControl.setValidators(phoneValidator);
            }
            telControl.updateValueAndValidity();
            emailControl.updateValueAndValidity();
            this.activeValidator = false;
          }
        })
    ];
  }

  passwordTelValidator(form: FormGroup) {
    const phoneValidator = [ Validators.required, Validators.minLength(10), Validators.maxLength(10) /*, PhoneValidator.validCountryPhone*/];
    const passwordValidator = [ Validators.required, Validators.minLength(3) ];
    const telControl = form.get('tel');
    const passwordControl = form.get('password');
    telControl.setValidators(phoneValidator);
    passwordControl.setValidators(passwordValidator);
    passwordControl.valueChanges.subscribe(
        (value: string) => {
          if (this.activeValidator === false) {
            this.activeValidator = true;
            passwordControl.setValidators(passwordValidator);
            passwordControl.updateValueAndValidity();
            if (value.length === 0) {
              telControl.setValidators(phoneValidator);
            } else {
              telControl.clearValidators();
              telControl.setValue('');
            }
            telControl.updateValueAndValidity();
            this.activeValidator = false;
          }
        });
    telControl.valueChanges.subscribe(
        (value: string) => {
          if (this.activeValidator === false) {
            this.activeValidator = true;
            telControl.setValidators(phoneValidator);
            telControl.updateValueAndValidity();
            if (value.length === 0) {
              passwordControl.setValidators(passwordValidator);
            } else {
              passwordControl.clearValidators();
              passwordControl.setValue('');
            }
            passwordControl.updateValueAndValidity();
            this.activeValidator = false;
          }
        });
  }

  cacheBustUrl(url: string): string {
      return url + '?rand=' + Math.random().toString(36).substring(7);
  }

  addNoBalanceAccounts(familyId: string, userId: string, accounts: any): Promise<any> {
    return this.accountsService.getPlaidTokens(familyId, userId).then(info => {
      const promises = [];
      info.forEach(plaidItem => {
        promises.push(this.accountsService.getAccessTokenStatus(familyId, userId, plaidItem.accessToken).then(status => {
          if (status.extraStatus === 'VERIFIED') {
            if (!plaidItem.balanceCache && status.metadata) {
              const metadata = status.metadata;
              accounts.push({
                fundingSourceType: 'nobalance',
                name: metadata.account.name,
                account_id: metadata.account.id,
                fundingSourceId: metadata.account.id,   // TODO: Hack to make addfunding work for now
                fundingSourceName: metadata.account.name, // TODO: hack part 2
                accessToken: plaidItem.accessToken
              });
            }
          }
        }));
      });
      return Promise.all(promises);
    });
  }

}
