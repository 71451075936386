import {EventEmitter, Injectable} from '@angular/core';
import {UsersService} from '../users/users.service';
import {AccountsService} from '../accounts/accounts.service';
import {RadiusService} from '../radius/radius.service';
import {SmartbalanceService} from '../smartbalance/smartbalance.service';
import {AppSettings} from '../../app.settings';
import {LedgerService} from '../ledger/ledger.service';
import {BrandsService} from '../brands/brands.service';

// user banking state:
//
// interface Account {
//    id: string
//    balance : {
//      current: number;
//      available: number;
//    }
//    state: enum { Verified, Microdeposits pending, NeedReverification }
//    transactions: Array<Transaction>
//    smartBalance: {
//      current: number
//      repeatingInboundAmount: number
//      repeatingOutboundAmount: number
//      startDate: Timestamp
//      endDate: Timestamp
//      repeatingTransactions: Array<Transaction>
//    }
// }
// interface wallit {
//    accounts: Array<Account>
//    wallit: {
//      balance: number
//      pendingInbound: number
//      pendingOutbound:
//    }
//    cashback: {
//      ytd: number
//      lifetime: number
// }

@Injectable({
  providedIn: 'root'
})
export class WallitService {

  private empty_wallit = {
    accountBalances: [],
    counterparties: [],
    plaidAccounts: [],
    plaidTokens: [],
    smartBalances: [],
    accountsBalance: 0,
    memberLedgerBalance: '0',
    counterpartiesLoaded: false,
    wallitBalance: 0,
    pendingInboundTransfers: 0,
    cashback: {
      ytd: 0,
      lifetime: 0
    }
  };

  private empty_transfers = {
    myBalance: undefined,
    pendingInboundTransfers: undefined
  }

  public wallit = this.empty_wallit;
  private transfers = this.empty_transfers;
  private updatingAccounts = false;

  private wallitChangedEvent: EventEmitter<{}> = new EventEmitter<{}>();
  private transferOccurredEvent: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
      private usersService: UsersService,
      private accountsService: AccountsService,
      private radiusService: RadiusService,
      private smartBalanceService: SmartbalanceService,
      private accountService: AccountsService,
      private ledgerService: LedgerService,
      private brandsService: BrandsService,
  ) {
    this.accountsService.monitorAccountChanged(() => {
      console.log('ACCOUNT CHANGED GET FUNDING SOURCES', this.updatingAccounts)
      this.getFundingSources();
    });
    this.usersService.meSubscribe(me => {
      if (me) {
        this.wallit.cashback = me.cashback;
        console.log('ME CHANGED GET FUNDING SOURCES', this.updatingAccounts)
        this.getFundingSources();
      } else {
        this.wallit = this.empty_wallit;
        this.wallitChangedEvent.emit(this.wallit);
      }
    });
  }

  monitorWallitChanged(func) {
    this.wallitChangedEvent.subscribe(value => func(value));
    func(this.wallit);
  }

  monitorTransferOccurred(func) {
    this.transferOccurredEvent.subscribe(value => func(value));
    func(this.transfers);
  }

  triggerTransferOccurred() {
    let pendingInboundAmount = 0;
    const promise1 = this.accountsService.getPlaidDwollaTransactions(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(data => {
      data.forEach(transaction => {
        if (transaction.status === 'processed' && transaction.transactionType === 'reload') {
          pendingInboundAmount += +transaction.amount;
        }
      });
      this.transfers.pendingInboundTransfers = pendingInboundAmount;
    }).catch(error => { console.log(error); });
    const promise2 = this.ledgerService.getUserBalance(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(balance => {
      this.transfers.myBalance = balance.value;
    });
    Promise.all([promise1, promise2]).then(_ => {
      this.transferOccurredEvent.emit();
    }).catch(error => {
      this.transferOccurredEvent.emit();
    });
  }

  private getFundingSources() {
    let reauthRequired;
    let sharedAccountInfo;
    const _this = this;
    const familyId = this.usersService.getCurrentFamilyId();
    const userId = this.usersService.getCurrentUserId();
    function useChildSources() {
      const member = _this.usersService.lookupFamilyMember(userId) || _this.usersService.me().user;
      return member.permissionLevel === 1;
    }
    function findPlaidAccount(account) {
      const plaidAccount = _this.wallit.plaidAccounts.find(plaid => (plaid.plaidAccount[0].type === 'credit' && plaid.plaidAccount[0].account_id === account.accountId) || (plaid.dwollaFundingSource &&
          plaid.dwollaFundingSource.id === account.fundingSourceId));
      return plaidAccount ? plaidAccount.plaidAccount[0] : undefined;
    }
    function lookupBalance(account) {
      if (account.fundingSourceType === 'balance') {
        return parseFloat(_this.wallit.memberLedgerBalance);
      }
      const plaidAccount = findPlaidAccount(account);
      if (plaidAccount) {
        return (plaidAccount.balances.available && account.fundingSourceType !== 'creditcard') ? plaidAccount.balances.available : plaidAccount.balances.current;
      }
      return undefined;
    }

    function setBalanceAccount() {
      _this.wallit.accountsBalance = 0;
      if (!_this.wallit.counterparties.find(counterparty => counterparty.fundingSourceType === 'balance')) {
        _this.wallit.counterparties.push(
            {
              fundingSourceType: 'balance',
              balances: {current: 0},
            }
        );
      }
    }
    function addVerifyPendingAccounts(): Promise<any> {
      return _this.accountsService.getPlaidTokens(familyId, userId).then(info => {
        // look for accounts waiting for verification
        const promises = [];
        _this.wallit.counterparties = [];
        info = info.filter(account => account.metadata.accounts[0].type !== 'brokerage');
        _this.wallit.plaidTokens = info;
        console.log('PLAID TOKENS', info)
        info.forEach(plaidItem => {
          promises.push(_this.accountService.getAccessTokenStatus(familyId, userId, plaidItem.accessToken).then(status => {
            if (status.extraStatus !== 'VERIFIED' || status.isManualMicrodeposit) {
              if (plaidItem.balanceCache) {
                plaidItem.metadata.accounts.forEach(account => {
                  if (account.verification_status || status.extraStatus === 'ITEM_LOGIN_REQUIRED') {
                    _this.wallit.counterparties.push({
                      fundingSourceType: account.verification_status === 'pending_manual_verification' ? 'needsverification' : (status.extraStatus === 'ITEM_LOGIN_REQUIRED' ? 'needsreauth' : 'pendingverification'),
                      name: account.name,
                      accessToken: plaidItem.accessToken,
                      accountId: account.account_id
                    });
                  }
                  if (status.extraStatus === 'NO_AUTH_ACCOUNTS') {
                    const creditAccount = {
                      fundingSourceType: 'creditcard',
                      name: account.name,
                      accessToken: plaidItem.accessToken,
                      accountId: account.id,
                      institution:  {name: plaidItem.metadata.institution.name, logo: ''}
                    };
                    _this.accountsService.getInstitutionInfo(creditAccount.institution.name).then(info => {
                      creditAccount.institution.logo = info.logo ? 'data:image/jpeg;base64,' + info.logo : 'assets/icon/icons8-mastercard-credit-card-90.png';
                    });
                    // it is a credit card
                    _this.wallit.counterparties.push(creditAccount);
                  }
                });
              } else {
                _this.wallit.counterparties.push({
                  fundingSourceType: 'needsverification',
                  name: status.metadata ? status.metadata.account.name : 'New Account',
                  accessToken: plaidItem.accessToken
                });
              }
            } else {
              /*
              if (!plaidItem.balanceCache && status.metadata) {
                const metadata = status.metadata;
                console.log('counterparties push 3')
                _this.wallit.counterparties.push({
                  fundingSourceType: 'nobalance',
                  name: metadata.account.name,
                  account_id: metadata.account.id,
                  accessToken: plaidItem.accessToken
                });
              }
              */

            }
          }));
        });
        return Promise.all(promises);
      });
    }
    _this.getPendingBalance().then(pendingBalance => {
      this.wallit.pendingInboundTransfers = pendingBalance;
      if (useChildSources()) {
        this.accountsService.getSharedAccountsInfo(familyId, userId).then(accountInfo => {
          if (accountInfo.sharedFundingSources.length > 0) {
            sharedAccountInfo = accountInfo;
            this.accountsService.getSharedAccountsBalances(familyId, userId).then(accounts => {
              this.wallit.counterparties = accounts;
              this.wallit.accountsBalance = 0;
              this.ledgerService.getUserBalance(familyId, userId).then(userBalance => {
                this.wallit.counterparties.push({fundingSourceType: 'balance', balances: {current: userBalance.value }});
                this.wallit.counterparties.forEach(bank => this.wallit.accountsBalance += +lookupBalance(bank));
              });
            }).catch(error => { console.log(error);
              reauthRequired = error.data.plaidAccounts[0].publicToken;
            });
          }  else {
            setBalanceAccount();
          }
        }).catch(error => { console.log(error);
          setBalanceAccount();
        });
      } else {
        addVerifyPendingAccounts().then(_ => {
          this.accountsService.getPlaidDwollaAccounts(familyId, userId).then(async response => {
            if (!this.updatingAccounts) {
              if (response) {
                if (response.length > 0) {
                  this.updatingAccounts = true;
                  const pendingCounterparties = [] // this.wallit.counterparties;
                  // this.wallit.counterparties = [];
                  this.wallit.plaidAccounts = [];
                  this.wallit.smartBalances = [];
                  await this.accountsService.getAccountBalances(familyId, userId).then(async data => {
                    this.wallit.plaidAccounts = data;
                    response.forEach(account => {
                        const pending = pendingCounterparties.find(cp => cp.accountId === account.plaidAccountId);
                        if (pending) {
                          account.fundingSourceType = pending.fundingSourceType;
                        }
                        this.wallit.counterparties.push(account);
                        account.institution = {name: '', logo: ''};
                      if (findPlaidAccount(account)) {
                        const accountToFindTokenFor = this.wallit.plaidAccounts.find(plaidAccount => plaidAccount.dwollaFundingSource.id === account.fundingSourceId).plaidAccount[0].account_id;
                        account.institution = {
                          name: this.wallit.plaidTokens.find(token => token.metadata.account_id === accountToFindTokenFor).metadata.institution.name
                        };
                        this.accountsService.getInstitutionInfo(account.institution.name).then(info => {
                          account.institution.logo = 'data:image/jpeg;base64,' + info.logo;
                        });
                      }
                    });
                    // see if there are any PRODUCT_NOT_READY accounts
                    // console.log('pendingcounterparties', pendingCounterparties)
                    pendingCounterparties.forEach(cp => {
                      if (cp.fundingSourceType !== 'bank' && cp.fundingSourceType !== 'balance') {
                        this.wallit.counterparties.push(cp);
                      }
                    });
                    this.wallit.accountsBalance = 0;
                    await this.accountsService.getSmartBalances(familyId, userId).then(balances => {
                      this.wallit.smartBalances = balances.filter(userBalance => !userBalance.errorCode);
                      this.wallit.smartBalances.forEach(userBalance => {
                        const counterparty = this.wallit.counterparties.find(cp => cp.plaidAccountId === userBalance.plaidAccountId);
                        if (counterparty) {
                          userBalance.bankName = counterparty.fundingSourceBankName;
                          userBalance.bankId = counterparty.fundingSourceId;  // TODO: needs to be intergration id
                          userBalance.plaidAccountId = counterparty.plaidAccountId;
                        } else {
                          userBalance.bankName = '* UNKNOWN *';
                        }
                      });
                    });
                    this.wallit.counterparties.forEach((bank) => {
                      if (bank.fundingSourceType === 'balance') {
                        // added by Kristijan ... TODO need to find better way for checking balances
                        // too much API calls for same resources
                        this.ledgerService.getUserBalance(familyId, userId).then(balance => {
                          this.wallit.memberLedgerBalance = balance.value; // real wallit balance for this member
                          this.wallit.accountsBalance += parseFloat(balance.value);
                        });
                      } else {
                        this.wallit.accountsBalance += +lookupBalance(bank);
                      }
                    });
                    this.wallitChangedEvent.emit(this.wallit);
                    this.updatingAccounts = false;
                    console.log('DONE')
                  }).catch(error => { console.log(error);
                    // console.log('pendingcounterparties', pendingCounterparties)
                    pendingCounterparties.forEach(cp => {
                      if (cp.fundingSourceType !== 'bank' && cp.fundingSourceType !== 'balance') {
                        console.log('push', cp)
                        this.wallit.counterparties.push(cp);
                      }
                    });
                    setBalanceAccount();
                  });
                } else {
                  setBalanceAccount();
                }
              } else {
                setBalanceAccount();
              }
            }
          }).catch(error => {
            setBalanceAccount();
          });
        });
      }
    }).catch(error => { console.log(error); });
  }

  private getPendingBalance(): Promise<any> {
    let pendingInboundAmount = 0;
    return this.accountsService.getPlaidDwollaTransactions(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(data => {
      data.forEach(transaction => {
        if (transaction.status === 'processed' && transaction.transactionType === 'reload') {
          pendingInboundAmount += +transaction.amount;
        }
      });
      return pendingInboundAmount;
    }).catch(error => { console.log(error);
      return 0;
    });
  }

  isRadius() {
    return this.brandsService.getUIConfig().bank === 'radius';
  }

}
