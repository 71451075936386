import {Component, Input, OnInit} from '@angular/core';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-advertiser-carousel',
  templateUrl: './advertiser-carousel.component.html',
  styleUrls: ['./advertiser-carousel.component.scss'],
})
export class AdvertiserCarouselComponent extends BaseComponent implements OnInit {

  @Input() title;

  protected advertisers;

  protected slideOpts = {
    spaceBetween: 10,
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination'
    }
  };

  constructor(
      apiService: ApiService,
      private cjService: CommissionJunctionService,
      private router: Router,
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.cjService.getAdvertisers().then(result => {
      this.advertisers = result;
    });
  }

  advertiserClicked(advertiser) {
      if (this.isLoggedIn) {
        this.router.navigate(['advertiserdetail', advertiser.name, advertiser.id]);
      }
  }

}
