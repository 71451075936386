import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppSettings} from '../../app.settings';
import {WallitService} from '../../services/wallit/wallit.service';
import {TeamsService} from '../../services/teams/teams.service';
import {UsersService} from '../../services/users/users.service';
import {BrandsService} from '../../services/brands/brands.service';


@Component({
  selector: 'app-user-carousel',
  templateUrl: './user-carousel.component.html',
  styleUrls: ['./user-carousel.component.scss']
})
export class OurlyUserCarouselComponent implements OnInit  {

  @Input() info;
  @Input() name = 'chart';
  @Input() carouselUpdate;

  @ViewChild('Slides') slides;

  protected goals = [];
  protected showSpendingChart = this.brandsService.getUIConfig().spendingChart;
  protected userId;
  protected wallit;
  protected earnKepUserId;
  protected earnKepFamilyId;
  protected smartBalances;

  private familyId;
  protected isBeginning;
  protected isEnd;
  public teams = [];

  private slideOpts = {
    spaceBetween: 10,
    watchOverflow: false,
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination',
      /*renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
        },*/
    }
  };

  constructor(
      private wallitService: WallitService,
      private teamsService: TeamsService,
      private usersService: UsersService,
      private brandsService: BrandsService
  ) { }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      this.smartBalances = JSON.parse(JSON.stringify(wallit.smartBalances));
      this.setPagerDots();
      setTimeout(() => {
        this.slides.update();
      }, 50);
    });
    this.teamsService.getUserMemberTeams(this.usersService.getCurrentUserId()).then(teams => {
      this.teams = teams;
      this.setPagerDots();
    });
  }

  private setPagerDots() {
    const extraSlideCount = this.smartBalances.length + this.teams.length;
    document.documentElement.style.setProperty('--swipervisibility',  extraSlideCount === 0 ? 'none' : 'block');
    this.isEnd = extraSlideCount >= 2;
  }

  leftArrow() {
    this.slides.slideNext();
  }

  rightArrow() {
    this.slides.slidePrev();
  }

  updateSliderIconState() {
    this.isEnd = this.slides.isEnd().then(isEnd => {
      this.isEnd = isEnd;
    });
    this.slides.isBeginning().then(isBeginning => {
      this.isBeginning = isBeginning;
    });
  }

}

