import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  constructor(
      private restangular: Restangular
  ) { }


  transfer(familyId: string, userId: string, destinationUserId: string, amount: number): Promise<any> {
    const data = {
      receiverUserId: destinationUserId,
      amount: amount,
    };
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('ledger').
     one('internalTransfers').customPOST(data).toPromise();
    // returns { "id": "244e3080-3166-11e9-9db0-270f86254d1f"
  }

  getUserBalance(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('ledger').one('balances').get().toPromise();
    // returns { "value": 92.80, "currency": "USD" }
  }

  getUserHistory(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v3').one('ledger').one('families', familyId).one('users', userId).one('transfers').getList().toPromise();
    // returns { "value": 92.80, "currency": "USD" }
  }

  getUsers(familyId: string): Promise<any> {
    return this.restangular.one('v3').one('ledger').one('families', familyId).one('users').get().toPromise();
  }

  getFamilyBalance(familyId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('ledger').one('balances').get().toPromise();
  }

  getFamilyLedger(familyId: string) {
    return this.restangular.one('v3').one('ledger').one('families', familyId).one('users').get().toPromise();
  }

  getAvailableUsers(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId)
    .one('users', userId).one('ledger').one('availableUsers').getList().toPromise();
  }

}

/*
/v3/ledger/families/:familyId/users/:userId/transfers
/v3/ledger/families/:familyId/users
/v3/ledger/families/:familyId/users/:userId/goals
/v3/ledger/families/:familyId/users/:userId/deposits
/v3/ledger/families/:familyId/users/:userId/withdrawals
*/
