
import { RadiusService } from '../../../../services/radius/radius.service';
import {
  Component,
  Input,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Output, EventEmitter
} from '@angular/core';
import {AccountsService} from '../../../../services/accounts/accounts.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SessionService} from '../../../../services/session/session.service';
import {IonItemSliding} from '@ionic/angular';
import {ToastComponent} from '../../../toast/toast.component';
import {UiAlertService} from '../../../../services/ui-alert/ui-alert.service';
import {listAnimator} from '../../../../services/animations/animations.service';
import {UsersService} from '../../../../services/users/users.service';
import {LedgerService} from '../../../../services/ledger/ledger.service';
import {ApiService} from '../../../../services/api/api.service';
import {GoalsService} from '../../../../services/goals/goals.service';
import {WallitService} from '../../../../services/wallit/wallit.service';
import {ContentService} from '../../../../services/content/content-service.service';

@Component({
  selector: 'app-user-accounts-radius',
  templateUrl: './user-accounts-radius.component.html',
  styleUrls: ['./user-accounts-radius.component.scss'],
  // animations: listAnimator
})
export class UserAccountsRadiusComponent implements OnInit, AfterContentChecked {

  protected accountsBalance;
  private plaidAccounts = [];
  private useChildSources = false;
  protected reauthRequired;
  protected spinnerLevel;
  private showNoAccountsMessage = false;
  private noAccountsMessage = false;
  private noAccountsTextMessage = this.contentService.getMessage('connectAnAccount');
  private showButtonLinkAnAccount = true;

  @Input() familyId: string;
  @Input() userId: string;
  @Input() balance: number;
  @Input() selectOnly = false;
  @Output() accountSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  private wallit;

  private goals;
  private wallitSelected;
  private selectedAccount;

  constructor(
      private accountService: AccountsService,
      private contentService: ContentService,
      private router: Router,
      private apiService: ApiService,
      private sessionService: SessionService,
      private toastComponent: ToastComponent,
      private uiAlertService: UiAlertService,
      private accountsService: AccountsService,
      private usersService: UsersService,
      private ledgerService: LedgerService,
      private radiusService: RadiusService,
      private changeDetector: ChangeDetectorRef,
      private route: ActivatedRoute,
      private goalsService: GoalsService,
      private wallitService: WallitService
  ) {
    this.accountsBalance = 0;
  }

  ngAfterContentChecked() {
    this.accountsBalance = 0;
    this.wallit.counterparties.forEach(element => {
      const findBankIdBalance = this.wallit.plaidAccounts.find(
        plaid => plaid.account_id === element.counterparty.plaid.account_id);
      if ( findBankIdBalance ) {
        this.accountsBalance += findBankIdBalance.balances.current;
      }
    });
    if (this.wallit.wallitBalance > 0) {
      this.accountsBalance = this.accountsBalance + this.wallit.wallitBalance;
    }
  }

  ngOnInit() {
    /*
    this.radiusService.getTransactionCategories().then(categories => {
      console.log('categories', categories);
    });
     */
    this.route.params.subscribe(params => {
      if (params) {
        if (params.familyId) { this.familyId = params.familyId; }
        if (params.userId) { this.userId = params.userId; }
      }
    });
    this.wallitService.monitorWallitChanged((wallit) => {
      this.wallit = wallit;
    });
    this.goalsService.goalsSubscribe(result => {
      this.goals = result;
    });
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    // TODO: Brittle code checking for Dashboard in URL. Switch to using @Input parameter
    const currUrl = this.router.url;
    if (currUrl.includes('tab-accounts')) {
      this.showNoAccountsMessage = true;
      this.noAccountsMessage = false;
    }
    if (currUrl.includes('ChildDetail')) {
      this.noAccountsTextMessage = this.contentService.getMessage('childNoAccounts');
      this.showButtonLinkAnAccount = false;
    }
    this.useChildSources = this.usersService.me().user.permissionLevel === 1;
  }

  testRadius() {
    this.router.navigate(['/test-radius-accounts', this.familyId, this.userId]);
  }

  goToWallit() {
    if (this.selectOnly) {
      this.accountSelectedEvent.emit({ wallit: true});
      this.wallitSelected = true;
      this.selectedAccount = undefined;
      return;
    }
    if (this.useChildSources) {
      this.router.navigate([ '/child-transfer' ] );
    } else {
      this.router.navigate(['/tabs/tab-activity']);
    }
  }

  needsVerify(account) {
    return account.fundingSourceType === 'needsverification';
  }

  pendingVerification(account): boolean {
    return account.fundingSourceType === 'pendingverification';
  }

  needsReauth(account) {
    return account.fundingSourceType === 'needsreauth';
  }

  getInstitutionIcon(account) {
    return account.institutionIcon ? 'data:image/jpeg;base64,' + account.institutionIcon : undefined;
  }

  getAccountBalance(account) {
    return this.wallit.accountBalances[account.counterparty.plaid.account_id] ? this.wallit.accountBalances[account.counterparty.plaid.account_id] : -1;
  }

  getAllocatedAmount(account) {
    let amount = 0;
    if (this.goals) {
      this.goals.forEach(goal => {
        if (goal.targetAccountId === account.counterparty.plaid.account_id) {
          amount += goal.balance.value;
        }
      });
    }
    return amount;
  }

  private findPlaidAccount(account) {
      const plaidAccount = this.plaidAccounts.find(plaid => plaid.dwollaFundingSource &&
        plaid.dwollaFundingSource.id === account.fundingSourceId);
      return plaidAccount ? plaidAccount.plaidAccount[0] : undefined;
  }

  goToRadiusAccount(account) {
    if (!this.pendingVerification(account) && !this.needsReauth(account)) {
      if (this.selectOnly) {
        this.accountSelectedEvent.emit(account);
        this.wallitSelected = false;
        this.selectedAccount = account;
        return;
      }
      if (account.id) {
        this.router.navigate(['/BankDetail', this.familyId, this.userId, account.counterparty.plaid.account_id, account.integrationId, account.id]);
      } else {
        this.router.navigate(['/BankDetail', this.familyId, this.userId, account.counterparty.plaid.account_id, account.integrationId]);
      }
    }
  }

  verifyClicked(event, account) {
    event.stopPropagation();
    this.accountsService.getPublicToken(account.accessToken).then(data => {
      this.router.navigate(['/Addbank', this.familyId, this.userId],
          { queryParams: {publicToken: data.publicToken, microDeposits: true}});
    });
  }

  reauthClicked(event, account) {
    event.stopPropagation();
    this.accountsService.getPublicToken(account.accessToken).then(data => {
      this.router.navigate(['/Addbank', this.familyId, this.userId], {queryParams: {publicToken: data.publicToken}});
    });
  }

  linkAnAccountClicked() {
    this.router.navigate(['/Addbank', this.familyId, this.userId]);
  }

  accountsClicked() {
    // this.router.navigate([ '/Payments', this.familyId, this.userId ]);
  }

  removeRadiusAccount(sliding: IonItemSliding, account) {
    this.uiAlertService.presentAlertConfirm(this.contentService.getMessage('accountRemoveQuestion')).then(confirm => {
      sliding.close();
      if (confirm) {
        this.goalsService.isAccountActiveForGoal(account).then(yes => {
          if (yes) {
            this.uiAlertService.presentAlertConfirm(this.contentService.getMessage('accountHasGoals')).then(ok => {
              if (ok) {
                this.doAccountRemove(account);
              }
            });
          } else {
            this.doAccountRemove(account);
          }
        });
      }
    });
  }

  private doAccountRemove(account) {
    this.toastComponent.presentToast(this.contentService.getMessage('accountRemoveWait'));
    if (account.counterparty.id) {
      this.radiusService.deleteFundingSource(this.familyId, this.userId, account.id, account.counterparty.id).then(del => {
        this.wallit.counterparties = this.wallit.counterparties.filter(counterparty =>
            counterparty.counterparty.id !== account.counterparty.id);
      }).catch((error) => {
        this.toastComponent.presentError(error);
      });
    } else {
      this.radiusService.deletePlaidSource(this.familyId, this.userId, account.accessToken).then(del => {
      }).catch((error) => {
        this.toastComponent.presentError(error);
      });
    }
  }

}
