import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import {ToastComponent} from '../../components/toast/toast.component';
const { Browser } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
  ) { }

  open(url: string) {
    Browser.open({
      'url': url,
      'toolbarColor': window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')
    });
  }
}
