import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from './guards/auth.guard';
import { LoginGuard} from './guards/login.guard';
import { BlockCookieBrowserGuard } from './guards/block-cookie-browser.guard';

const routes: Routes = [
  { path: '', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'Dashboard', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'developer/:familyId/:userId', loadChildren: './pages/developer/developer.module#DeveloperPageModule' },

    // dashboard and user profile
  { path: 'ChildDetail/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/child-detail/child-detail.module#ChildDetailPageModule' },
  { path: 'CoParentDetail/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/co-parent-detail/co-parent-detail.module#CoParentDetailPageModule' },
  { path: 'WallitActions', canActivate: [ AuthGuard ], loadChildren: './pages/wallit-actions/wallit-actions.module#WallitActionsPageModule' },
  { path: 'Profile', canActivate: [ AuthGuard ], loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'EditProfile', canActivate: [ AuthGuard ], loadChildren: './pages/forms/edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'ProfileOther', canActivate: [ AuthGuard ], loadChildren: './pages/profile-other/profile-other.module#ProfileOtherPageModule' },
  { path: 'AddAChild/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-achild/add-achild.module#AddAChildPageModule' },
  { path: 'AddACoparent/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-acoparent/add-acoparent.module#AddACoparentPageModule' },
  { path: 'ProfileNotificationSettings', canActivate: [ AuthGuard ], loadChildren:
    './pages/forms/profile-notification-settings/profile-notification-settings.module#ProfileNotificationSettingsPageModule' },
  { path: 'ProfileNotifications', canActivate: [ AuthGuard ],
    loadChildren: './pages/profile-notifications/profile-notifications.module#ProfileNotificationsPageModule' },
  { path: 'ProfileFamily', canActivate: [ AuthGuard ], loadChildren: './pages/profile-family/profile-family.module#ProfileFamilyPageModule' },
  { path: 'chartsettings', canActivate: [ AuthGuard ], loadChildren: './pages/forms/chartsettings/chartsettings.module#ChartsettingsPageModule' },
  { path: 'confirm-lose-changes', canActivate: [ AuthGuard ],
    loadChildren: './pages/modals/confirm-lose-changes/confirm-lose-changes.module#ConfirmLoseChangesPageModule' },
  { path: 'invitationhistory/:familyId/:userId', canActivate: [ AuthGuard ],
    loadChildren: './pages/invitationhistory/invitationhistory.module#InvitationhistoryPageModule' },

  // team purchases
  { path: 'AddAPurchase/:teamId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-apurchase/add-apurchase.module#AddAPurchasePageModule' },
  { path: 'AddAPurchase/:teamId/:purchaseId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-apurchase/add-apurchase.module#AddAPurchasePageModule' },
  { path: 'viewReimbursements/:teamId', canActivate: [ AuthGuard ], loadChildren: './pages/view-reimbursements/view-reimbursements.module#ViewReimbursementsPageModule' },

  // goals
  { path: 'AddAGoal/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-agoal/add-agoal.module#AddAGoalPageModule' },
  { path: 'GoalDetail/:familyId/:userId/:goalId', canActivate: [ AuthGuard ], loadChildren: './pages/goal-detail/goal-detail.module#GoalDetailPageModule' },
  { path: 'goal-adjustfunding/:familyId/:userId/:goalId', loadChildren: './pages/forms/goal-adjustfunding/goal-adjustfunding.module#GoalAdjustfundingPageModule' },
  { path: 'goal-adjustfunding/:goalId/:amount', loadChildren: './pages/forms/goal-adjustfunding/goal-adjustfunding.module#GoalAdjustfundingPageModule' },
  { path: 'goals/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/goals/goals.module#GoalsPageModule' },

  // gift cards
  { path: 'SelectGiftCard', canActivate: [ AuthGuard ], loadChildren: './pages/select-gift-card/select-gift-card.module#SelectGiftCardPageModule' },
  { path: 'FillOutGiftCard/:id', canActivate: [ AuthGuard ], loadChildren: './pages/forms/fill-out-gift-card/fill-out-gift-card.module#FillOutGiftCardPageModule' },
  { path: 'giftcardhistory/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/giftcardhistory/giftcardhistory.module#GiftcardhistoryPageModule' },
  { path: 'gift-card-order-detail/:familyId/:userId/:orderId', canActivate: [ AuthGuard ], loadChildren: './pages/gift-card-order-detail/gift-card-order-detail.module#GiftCardOrderDetailPageModule' },

  { path: 'Giving', canActivate: [ AuthGuard ], loadChildren: './pages/giving/giving.module#GivingPageModule' },

    // FTUE, signup/signin
  // { path: 'intro-signup', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/intro-signup/intro-signup.module#IntroSignupPageModule' },
  { path: 'invite/:id', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/invite/invite.module#InvitePageModule' },
  { path: 'SignIn', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-in/sign-in.module#SignInPageModule' },
  { path: 'enter-sms-code/:phone/:birthdate/:invitationId',
    loadChildren: './pages/forms/enter-sms-code/enter-sms-code.module#EnterSmsCodePageModule' },
  { path: 'enter-sms-code/:phone/:birthdate',
    loadChildren: './pages/forms/enter-sms-code/enter-sms-code.module#EnterSmsCodePageModule' },
  { path: 'Setup1', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup1/setup1.module#Setup1PageModule' },
  { path: 'setup1-next', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup1-next/setup1-next.module#Setup1NextPageModule' },
  { path: 'Setup2', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup2/setup2.module#Setup2PageModule' },
  { path: 'Setup3', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup3/setup3.module#Setup3PageModule' },
  { path: 'setup4/:bankId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup4/setup4.module#Setup4PageModule' },
  { path: 'setup-invite-parent', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup-invite-parent/setup-invite-parent.module#SetupInviteParentPageModule' },
  { path: 'setup-kid-splash', canActivate: [ AuthGuard ], loadChildren: './pages/forms/setup-kid-splash/setup-kid-splash.module#SetupKidSplashPageModule' },
  { path: 'forgot-password', outlet: 'modal',
    loadChildren: './pages/modals/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'signup-start', loadChildren: './pages/forms/signup-start/signup-start.module#SignupStartPageModule' },
  // { path: 'signup-magiccode', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-up-magiccode/sign-up-magiccode.module#SignupMagiccodePageModule' },
  { path: 'signup-username', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-up-username/sign-up-username.module#SignupUsernamePageModule' },
  // { path: 'signup-phone', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-up-phone/sign-up-phone.module#SignupPhonePageModule' },
  { path: 'signup-magiccode-next',
    loadChildren: './pages/forms/sign-up-magiccode-next/sign-up-magiccode-next.module#SignUpMagiccodeNextPageModule' },
  { path: 'resetpassword/:resetCode', loadChildren: './pages/forms/resetpassword/resetpassword.module#ResetpasswordPageModule' },
  { path: 'requestnewpassword', loadChildren: './pages/forms/requestnewpassword/requestnewpassword.module#RequestnewpasswordPageModule' },
  { path: 'forgotusername', loadChildren: './pages/forms/forgotusername/forgotusername.module#ForgotusernamePageModule' },
  { path: 'token/:token/:refresh', loadChildren: './pages/token/token.module#TokenPageModule' },

    // tasks
  { path: 'AddATask/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-atask/add-atask.module#AddATaskPageModule' },
  { path: 'add-task-location/:familyId/:userId/:categoryId', canActivate: [ AuthGuard ],
    loadChildren: './pages/forms/add-task-location/add-task-location.module#AddTaskLocationPageModule' },
  { path: 'AssignTask/:familyId/:userId/:taskId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/assign-task/assign-task.module#AssignTaskPageModule' },
  { path: 'start-task/:familyId/:userId/:taskId/:dueAt', canActivate: [ AuthGuard ], loadChildren: './pages/forms/start-task/start-task.module#StartTaskPageModule' },
  { path: 'approve-task/:familyId/:userId/:taskId/:dueAt', canActivate: [ AuthGuard ],
    loadChildren: './pages/forms/approve-task/approve-task.module#ApproveTaskPageModule' },
  { path: 'Tasks/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/tasks/tasks.module#TasksPageModule' },

    // rewards
  { path: 'rewards', loadChildren: './pages/rewards/rewards.module#RewardsPageModule' },
  { path: 'rewarddetail/:rewardId', loadChildren: './pages/forms/rewarddetail/rewarddetail.module#RewarddetailPageModule' },

  // accounts
  { path: 'Payments/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/tab-accounts/payments.module#PaymentsPageModule' },
  { path: 'edit-allowance/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/edit-allowance/edit-allowance.module#EditAllowancePageModule' },
  { path: 'Transfer/:direction/:bankId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/transfer/transfer.module#TransferPageModule' },
  { path: 'Transfer', canActivate: [ AuthGuard ], loadChildren: './pages/forms/transfer/transfer.module#TransferPageModule' },
  { path: 'AddCreditCard/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/forms/add-credit-card/add-credit-card.module#AddCreditCardPageModule' },
  { path: 'ShareAccount/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], loadChildren: './pages/share-account/share-account.module#ShareAccountPageModule' },
  { path: 'ledgerhistory/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: './pages/ledgerhistory/ledgerhistory.module#LedgerhistoryPageModule' },

  // modal outlet paths
  { path: 'modal-account-added', outlet: 'modal',
    loadChildren: './pages/modals/modal-account-added/modal-account-added.module#ModalAccountAddedPageModule' },
  { path: 'credit-card-added', outlet: 'modal',
    loadChildren: './pages/modals/credit-card-added/credit-card-added.module#CreditCardAddedPageModule' },
  { path: 'goal-created', outlet: 'modal',
    loadChildren: './pages/modals/goal-created/goal-created.module#GoalCreatedPageModule' },
  { path: 'transfer-sent', outlet: 'modal', loadChildren: './pages/modals/transfer-sent/transfer-sent.module#TransferSentPageModule' },
  { path: 'gift-card-received', outlet: 'modal',
    loadChildren: './pages/modals/gift-card-received/gift-card-received.module#GiftCardReceivedPageModule' },
  { path: 'confirm-account-remove/:id', outlet: 'modal',
    loadChildren: './pages/modals/confirm-account-remove/confirm-account-remove.module#ConfirmAccountRemovePageModule' },
  { path: 'confirm-approve-task/:id', outlet: 'modal',
    loadChildren: './pages/modals/confirm-approve-task/confirm-approve-task.module#ApproveTaskPageModule' },
  { path: 'confirm-dashboard-sortby', outlet: 'modal',
    loadChildren: './pages/modals/confirm-dashboard-sortby/confirm-dashboard-sortby.module#ConfirmDashboardSortbyPageModule' },
  { path: 'confirm-reject-task/:id', outlet: 'modal',
    loadChildren: './pages/modals/confirm-reject-task/confirm-reject-task.module#ConfirmRejectTaskPageModule' },
  { path: 'task-completed/:id', outlet: 'modal',
    loadChildren: './pages/modals/task-completed/task-completed.module#TaskCompletedPageModule' },


  { path: 'rebates/:latitude/:longitude/:radius', loadChildren: './pages/rebates/rebates.module#RebatesPageModule' },
  { path: 'rebates/:categorySlug/:latitude/:longitude/:radius', loadChildren: './pages/rebates/rebates.module#RebatesPageModule' },
  { path: 'rebateslocal/:latitude/:longitude/:radius', loadChildren: './pages/rebateslocal/rebateslocal.module#RebateslocalPageModule' },
  { path: 'rebateslocal/:categorySlug/:latitude/:longitude/:radius', loadChildren: './pages/rebateslocal/rebateslocal.module#RebateslocalPageModule' },
  { path: 'rebatedetail/:type/:id', loadChildren: './pages/rebatedetail/rebatedetail.module#RebatedetailPageModule' },
  { path: 'tabs', loadChildren: './pages/tabs/tabs.module#TabsPageModule' },
  { path: 'learnmore', loadChildren: './pages/learnmore/learnmore.module#LearnmorePageModule' },
  { path: 'cashback', loadChildren: './pages/cashback/cashback.module#CashbackPageModule' },
  { path: 'sendmoney', loadChildren: './pages/sendmoney/sendmoney.module#SendmoneyPageModule' },
  { path: 'sendmoney/:userId/:requestId/:amount', canActivate: [ AuthGuard ], loadChildren: './pages/sendmoney/sendmoney.module#SendmoneyPageModule' },
  { path: 'userdetail/:userId', loadChildren: './pages/userdetail/userdetail.module#UserdetailPageModule' },
  { path: 'tab-activity-cashback', loadChildren: './pages/tab-activity-cashback/tab-activity-cashback.module#TabActivityCashbackPageModule' },
  { path: 'account-adds', loadChildren: './pages/account-adds/account-adds.module#AccountAddsPageModule' },
  { path: 'share-goal/:goalId', loadChildren: './pages/share-goal/share-goal.module#ShareGoalPageModule' },
  { path: 'set-sharegoal-header', loadChildren: './pages/modals/set-sharegoal-header/set-sharegoal-header.module#SetSharegoalHeaderPageModule' },
  { path: 'smart-balance-settings/:bankId', canActivate: [ AuthGuard ], loadChildren: './pages/smart-balance-settings/smart-balance-settings.module#SmartBalanceSettingsPageModule' },
  { path: 'smart-balance-settings/:bankId/:integrationId', canActivate: [ AuthGuard ], loadChildren: './pages/smart-balance-settings/smart-balance-settings.module#SmartBalanceSettingsPageModule' },
  { path: 'set-frequency', canActivate: [ AuthGuard ], loadChildren: './pages/modals/set-frequency/set-frequency.module#SetFrequencyPageModule' },
  { path: 'EmailVerified', loadChildren: './pages/email-verified/email-verified.module#EmailVerifiedPageModule' },
  { path: 'advertiserdetail/:title/:id', loadChildren: './pages/advertiser-detail/advertiser-detail.module#AdvertiserDetailPageModule' },
  { path: 'team-manager', canActivate: [ AuthGuard ], loadChildren: './pages/team-manager/team-manager.module#TeamManagerPageModule' },
  { path: 'team-create-workspace', canActivate: [ AuthGuard ], loadChildren: './pages/team-create-workspace/team-create-workspace.module#TeamCreateWorkspacePageModule' },
  { path: 'team-create-workspace/:mode', canActivate: [ AuthGuard ], loadChildren: './pages/team-create-workspace/team-create-workspace.module#TeamCreateWorkspacePageModule' },
  { path: 'team-add-members/:id', canActivate: [ AuthGuard ], loadChildren: './pages/team-add-members/team-add-members.module#TeamAddMembersPageModule' },
  { path: 'team-add-members/:id/:mode', canActivate: [ AuthGuard ], loadChildren: './pages/team-add-members/team-add-members.module#TeamAddMembersPageModule' },
  { path: 'team-set-benefits/:id', canActivate: [ AuthGuard ], loadChildren: './pages/team-set-benefits/team-set-benefits.module#TeamSetBenefitsPageModule' },
  { path: 'team-set-benefits/:id/:mode', canActivate: [ AuthGuard ], loadChildren: './pages/team-set-benefits/team-set-benefits.module#TeamSetBenefitsPageModule' },
  { path: 'teams', loadChildren: './pages/teams/teams.module#TeamsPageModule' },
  { path: 'team-list', canActivate: [ AuthGuard ], loadChildren: './pages/team-list/team-list.module#TeamListPageModule' },
  { path: 'team-details', canActivate: [ AuthGuard ], loadChildren: './pages/team-manager/team-details/team-details.module#TeamDetailsPageModule' },
  { path: 'team-details/:id', canActivate: [ AuthGuard ], loadChildren: './pages/team-manager/team-details/team-details.module#TeamDetailsPageModule' },
  { path: 'team-edit/:id/:action', canActivate: [ AuthGuard ], loadChildren: './pages/team-manager/team-edit/team-edit.module#TeamEditPageModule' },
  { path: 'stripe-success/:id/:destination', loadChildren: './pages/stripe-success/stripe-success.module#StripeSuccessPageModule' },
  { path: 'stripe-cancel/:destination', loadChildren: './pages/stripe-cancel/stripe-cancel.module#StripeCancelPageModule' },
  { path: 'stripe-portal-return/:destination', loadChildren: './pages/stripe-portal-return/stripe-portal-return.module#StripePortalReturnPageModule' },
  { path: 'wellness', redirectTo: '/signup-username?team=true', pathMatch: 'full' },
  { path: 'team-signup', loadChildren: './pages/team-signup/team-signup.module#TeamSignupPageModule' },
  { path: 'stripecreditcard/:id', loadChildren: './pages/stripecreditcard/stripecreditcard.module#StripecreditcardPageModule' },
  { path: 'emailcode/:email', loadChildren: './pages/emailcode/emailcode.module#EmailcodePageModule' },
  { path: 'team-member-detail/:teamId/:memberId', canActivate: [ AuthGuard ], loadChildren: './pages/team-member-detail/team-member-detail.module#TeamMemberDetailPageModule' },
  { path: 'team-plan/:teamId', canActivate: [ AuthGuard ], loadChildren: './pages/team-plan/team-plan.module#TeamPlanPageModule' },
  { path: 'team-member-reward/:teamId/:memberId', canActivate: [ AuthGuard ], loadChildren: './pages/team-member-reward/team-member-reward.module#TeamMemberRewardPageModule' },
  { path: 'team-upload-members/:teamId', canActivate: [ AuthGuard ], loadChildren: './pages/team-upload-members/team-upload-members.module#TeamUploadMembersPageModule' },
  { path: 'team-one-time-reward/:teamId', canActivate: [ AuthGuard ], loadChildren: './pages/team-one-time-reward/team-one-time-reward.module#TeamOneTimeRewardPageModule' },
  { path: 'team-activity', loadChildren: './pages/team-activity/team-activity.module#TeamActivityPageModule' },
];

const radiusRoutes = [
  // paths that depend on back end bank being radius
  { path: 'Addbank/:familyId/:userId',  canActivate: [ AuthGuard ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/addbank-radius/addbank-radius.module#AddbankRadiusPageModule' },
  { path: 'addfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/addfunding-radius/addfunding-radius.module#AddfundingRadiusPageModule' },
  { path: 'withdrawfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/withdrawfunding-radius/withdrawfunding-radius.module#WithdrawfundingRadiusPageModule' },
  { path: 'transferhistory/:familyId/:userId/:bankId', canActivate: [ AuthGuard ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/tab-transfers-history/tab-transfers-history.module#TabTransfersHistoryPageModule' },
  { path: 'BankDetail/:familyId/:userId/:bankId/:integrationId/:fundingSourceId', canActivate: [ AuthGuard  ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/bank-detail/bank-detail.module#BankDetailPageModule' },
  { path: 'BankDetail/:familyId/:userId/:bankId/:integrationId', canActivate: [ AuthGuard  ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/bank-detail/bank-detail.module#BankDetailPageModule' },
  { path: 'child-transfer', canActivate: [ AuthGuard  ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/child-transfer/child-transfer.module#ChildTransferPageModule' },
  { path: 'transaction-detail/:familyId/:userId/:transactionId', canActivate: [ AuthGuard  ], data: { bank: 'radius'}, loadChildren: './pages/bank-providers/radius/transaction-detail/transaction-detail.module#TransactionDetailPageModule' },
];

const dwollaRoutes: Routes = [
  // paths that depend on back end bank being dwolla
  { path: 'Addbank/:familyId/:userId',  canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/addbank-dwolla/addbank-dwolla.module#AddbankDwollaPageModule' },
  { path: 'addfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/addfunding-dwolla/addfunding-dwolla.module#AddfundingDwollaPageModule' },
  { path: 'withdrawfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/withdrawfunding-dwolla/withdrawfunding-dwolla.module#WithdrawfundingDwollaPageModule' },
  { path: 'transferhistory/:familyId/:userId/:bankId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/tab-transfers-history-dwolla/tab-transfers-history-dwolla.module#TabTransfersHistoryDwollaPageModule' },
  { path: 'BankDetail/:familyId/:userId/:bankId/:fundingSourceId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/bank-detail-dwolla/bank-detail-dwolla.module#BankDetailPageModule' },
  { path: 'BankDetail/:familyId/:userId/:bankId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/bank-detail-dwolla/bank-detail-dwolla.module#BankDetailPageModule' },
  { path: 'child-transfer', canActivate: [ AuthGuard   ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/child-transfer-dwolla/child-transfer-dwolla.module#ChildTransferDwollaPageModule' },
  { path: 'transaction-detail/:familyId/:userId/:transactionId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: './pages/bank-providers/dwolla/transaction-detail-dwolla/transaction-detail-dwolla.module#TransactionDetailDwollaPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot([...routes, ...dwollaRoutes])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
